export const SET_AGENTS = 'SET_AGENTS'
export const SHARE_AGENT = 'SHARE_AGENT'
export const SET_AGENT = 'SET_AGENT'
export const SET_PIECES = 'SET_PIECES'
export const SET_PIECE = 'SET_PIECE'
export const SET_PIECE_SIDEBAR_TYPE = 'SET_PIECE_SIDEBAR_TYPE'
export const SET_PIECE_SIDEBAR_OPEN = 'SET_PIECE_SIDEBAR_OPEN'
export const SET_OAUTH_STARTED = 'SET_OAUTH_STARTED'
export const SET_OAUTH_DATA = 'SET_OAUTH_DATA'
export const SET_OAUTH_COMPLETED = 'SET_OAUTH_COMPLETED'
export const SET_CONNECTIONS = 'SET_CONNECTIONS'
export const SET_CONNECTION = 'SET_CONNECTION'
export const SET_EDGE_ID = 'SET_EDGE_ID'
export const LAST_ADDED_STEP = 'LAST_ADDED_STEP'
export const UPDATE_PIECE = 'UPDATE_PIECE'
export const UPDATE_PIECE_VALID_STATE = 'UPDATE_PIECE_VALID_STATE'
export const SET_RUN_RESULTS = 'SET_RUN_RESULTS'
export const SET_LOCAL_AGENT = 'SET_LOCAL_AGENT'
export const SET_LOCAL_AGENTS = 'SET_LOCAL_AGENTS'
export const setPiece = (data) => ({
    type: SET_PIECE,
    payload: data
})
export const setPieceSidebartype = (data) => ({
    type: SET_PIECE_SIDEBAR_TYPE,
    payload: data
})
export const setPieceSidebarOpen = (data) => ({
    type: SET_PIECE_SIDEBAR_OPEN,
    payload: data
})
export const setOauthStarted = (data) => ({
    type: SET_OAUTH_STARTED,
    payload: data
})
export const setOauthData = (data) => ({
    type: SET_OAUTH_DATA,
    payload: data
})
export const setOauthCompleted = (data) => ({
    type: SET_OAUTH_COMPLETED,
    payload: data
})
