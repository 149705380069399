/* eslint-disable react/prop-types */
import React from 'react'
import Page500 from './views/Page500'
// import axiosInstance from './utils/axios'
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }
    componentDidCatch(error, errorInfo) {
        // try {
        //     axiosInstance.post('/logger', { data: { error: error.toString(), stack: error.stack, errorInfo } })
        // } catch (e) {}
        console.log({ error, errorInfo })
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Page500 />
        }
        return this.props.children
    }
}

export default ErrorBoundary
