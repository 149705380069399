const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/usertables',
    fontFamily: `'Poppins, Roboto', sans-serif`,
    borderRadius: 10
}
export const defaultSettings = {
    // themeMode: process.env.REACT_APP_THEME ?? 'light',
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'purple',
    themeStretch: false
}

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 62,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
}

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 276,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 32,
    DASHBOARD_ITEM_SUB_HEIGHT: 25,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 25
}

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20
}
export default config
export const categories = [
    'All Categories',
    'Marketing',
    `Sales`,
    `Customer Service`,
    `Research`,
    `HR`,
    `Finance`,
    `Legal`,
    `IT`,
    `Operations`
]
