/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
// @mui
import { List, Collapse, Box } from '@mui/material'
//
import { NavItemRoot, NavItemSub } from './NavItem'
import { getActive } from '..'

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
    list: PropTypes.object,
    isCollapse: PropTypes.bool
}

export function NavListRoot({ list, isCollapse }) {
    const { pathname } = useLocation()

    const active = getActive(list.path, pathname)

    const [open, setOpen] = useState(active)

    const hasChildren = list.children.length > 0

    if (hasChildren) {
        return (
            <>
                <NavItemRoot item={list} isCollapse={isCollapse} active={active} open={open} onOpen={() => setOpen(!open)} />
                
                {!isCollapse && (
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <List component='div' sx={{ ml: 2}}>
                            {(list.children || []).map((item) => (
                                <NavListSub key={item.title + item.path} list={item} />
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        )
    }

    return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
    list: PropTypes.object
}

function NavListSub({ list }) {
    const { pathname } = useLocation()

    const active = getActive(list.path, pathname)

    const [open, setOpen] = useState(active)

    const hasChildren = list.children

    if (hasChildren) {
        return (
            <>
                <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />

                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding sx={{ pl: 3 }}>
                        {(list.children || []).map((item) => (
                            <NavItemSub key={item.title + item.path} item={item} active={getActive(item.path, pathname)} />
                        ))}
                    </List>
                </Collapse>
            </>
        )
    }

    return <NavItemSub item={list} active={active} />
}
