export const SET_COLUMN_AI_CONFIG = 'SET_COLUMN_AI_CONFIG'
export const SET_AI_COLUMN_EDITOR_FULL_SCREEN = 'SET_AI_COLUMN_EDITOR_FULL_SCREEN'
export const IS_AI_COLUMN_EDITOR_IN_EDIT_MODE = 'IS_AI_COLUMN_EDITOR_IN_EDIT_MODE'
export const SET_COLUMN_TYPE = 'SET_COLUMN_TYPE'
export const SET_COLUMN_AI_CONFIG_OPEN = 'SET_COLUMN_AI_CONFIG_OPEN'
export const SET_TABLE = 'SET_TABLE'
export const SET_ROWS = 'SET_ROWS'
export const SET_COLUMNS = 'SET_COLUMNS'
export const ADD_ROW = 'ADD_ROW'
export const DELETE_ROW = 'DELETE_ROW'
export const UPDATE_ROW = 'UPDATE_ROW'
export const ADD_COLUMN = 'ADD_COLUMN'
export const OPEN_CELL_LOGS = 'OPEN_CELL_LOGS'
export const OPEN_ROW_DETAILS = 'OPEN_ROW_DETAILS'
export const ADD_NEW_NORMAL_COLUMN = 'ADD_NEW_NORMAL_COLUMN'
export const SET_ANCHOR_EL = 'SET_ANCHOR_EL'
export const OPEN_CELL_DETAILS = 'OPEN_CELL_DETAILS'
export const SET_GRID_COLUMNS = 'SET_GRID_COLUMNS'
export const ADD_COLUMN_AFTER = 'ADD_COLUMN_AFTER'
export const SET_CELLS = 'SET_CELLS'
export const SET_CELLS_GENERATIONS = 'SET_CELLS_GENERATIONS'
export const addColumnAfterHandler = (payload) => ({
    type: ADD_COLUMN_AFTER,
    payload
})
export const setGridColumns = (payload) => ({
    type: SET_GRID_COLUMNS,
    payload
})
export const setColumnAiConfig = (payload) => ({
    type: SET_COLUMN_AI_CONFIG,
    payload
})
export const setAiColumnEditorFullScreen = (payload) => ({
    type: SET_AI_COLUMN_EDITOR_FULL_SCREEN,
    payload
})
export const setIsAiColumnEditorInEditMode = (payload) => ({
    type: IS_AI_COLUMN_EDITOR_IN_EDIT_MODE,
    payload
})
export const setColumnAiConfigOpen = (payload) => ({
    type: SET_COLUMN_AI_CONFIG_OPEN,
    payload
})
export const setTable = (payload) => ({
    type: SET_TABLE,
    payload
})
export const setRows = (payload) => ({
    type: SET_ROWS,
    payload
})
export const setColumns = (payload) => ({
    type: SET_COLUMNS,
    payload
})
export const setCells = (payload) => ({
    type: SET_CELLS,
    payload
})
export const setCellGenerations = (payload) => ({
    type: SET_CELLS_GENERATIONS,
    payload
})
export const addRow = (payload) => ({
    type: ADD_ROW,
    payload
})
export const deleteRow = (payload) => ({
    type: DELETE_ROW,
    payload
})
export const updateRow = (payload) => ({
    type: UPDATE_ROW,
    payload
})
export const addColumn = (payload) => ({
    type: ADD_COLUMN,
    payload
})
export const setColumnType = (payload) => ({
    type: SET_COLUMN_TYPE,
    payload
})
export const openCellLogs = (payload) => ({
    type: OPEN_CELL_LOGS,
    payload
})
export const openRowDetails = (payload) => ({
    type: OPEN_ROW_DETAILS,
    payload
})
export const createNewNormalColumn = (payload) => ({
    type: ADD_NEW_NORMAL_COLUMN,
    payload
})
export const setAnchorEl = (payload) => ({
    type: SET_ANCHOR_EL,
    payload
})
export const openCellDetails = (payload) => ({
    type: OPEN_CELL_DETAILS,
    payload
})
