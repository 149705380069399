/* eslint-disable react/no-unescaped-entities */
import { m } from 'framer-motion'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Button, Typography, Container } from '@mui/material'
// components
import Page from '../ui-component/Page'
import ForbiddenIllustration from '../assets/images/illustration_403'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Page403() {
    return (
        <Page title='403 Forbidden'>
            <Container>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <m.div>
                        <Typography variant='h3' paragraph>
                            No permission
                        </Typography>
                    </m.div>

                    <m.div>
                        <Typography sx={{ color: 'text.secondary' }}>
                            The page you're trying access has restricted access.
                            <br />
                            Please refer to your system administrator
                        </Typography>
                    </m.div>

                    <m.div>
                        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                    </m.div>

                    <Button to='/' size='large' variant='contained' component={RouterLink}>
                        Go to Home
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    )
}
