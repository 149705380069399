import { useRoutes } from 'react-router-dom'

// routes
import { getRoutes, PublicRoutes, NotFoundRoutes } from './MainRoutes'
import config from 'config'
import AuthRoutes from './AuthRoutes'
import useAuth from 'hooks/useAuth'
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth()
    const isAdmin = user?.role === 'admin'
    const MainRoutes = getRoutes(isAdmin)

    return useRoutes([PublicRoutes, AuthRoutes, MainRoutes ?? { path: '/dashboard', children: [] }, NotFoundRoutes], config.basename)
}
