import { useLocation, Outlet } from 'react-router-dom'
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material'
// components
import Logo from '../../components/Logo'
//
import MainFooter from './MainFooter'
import MainHeader from './MainHeader'

// ----------------------------------------------------------------------

export default function MainLayout() {
    return (
        <Stack sx={{ minHeight: 1 }}>
            <MainHeader />

            <Outlet />

            <Box sx={{ flexGrow: 1 }} />
            <MainFooter />
        </Stack>
    )
}
