// routes
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22
}

const menuConfig = [
    {
        title: 'Home',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        href: 'https://meerkats.ai'
    },
    // { title: 'About us', path: '/about' },
    // { title: 'Contact us', path: '/contact' },
    // { title: 'FAQs', path: '/faqs' }
    { title: 'Blog', href: 'https://meerkats.ai/blogs' },
    { title: 'Pricing', href: 'https://meerkats.ai/pricing' }
    // { title: 'Pricing', path: '/pricing' },
    // { title: 'Payment', path: '/payment' },
    // { title: 'Maintenance', path: '/maintenance' },
    // { title: 'Coming Soon', path: '/comingSoon' }
]

export default menuConfig
