/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
const OAuthCallback = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const checkForCode = () => {
        const queryKeys = Array.from(queryParams.keys())
        const data = {}
        queryKeys.forEach((key) => {
            data[key] = queryParams.get(key)
        })
        if (data.code) {
            const bc = new BroadcastChannel('jippybot1')
            bc.postMessage({ type: 'oauth', data })
        }
        window.close()
    }
    useEffect(() => {
        checkForCode()
    }, [])
    return <>{JSON.stringify(queryParams, null, 2)}</>
}

export default OAuthCallback
