import subdomain from 'api/subdomain'
import {
    SET_PIECES,
    SET_PIECE,
    SET_PIECE_SIDEBAR_TYPE,
    SET_PIECE_SIDEBAR_OPEN,
    SET_OAUTH_STARTED,
    SET_OAUTH_DATA,
    SET_OAUTH_COMPLETED,
    SET_CONNECTIONS,
    SET_AGENT,
    SET_AGENTS,
    SET_CONNECTION,
    SET_EDGE_ID,
    LAST_ADDED_STEP,
    UPDATE_PIECE,
    UPDATE_PIECE_VALID_STATE,
    SET_RUN_RESULTS,
    SET_LOCAL_AGENT,
    SET_LOCAL_AGENTS
} from '../agentActions'

export const additionalActions = [
    {
        type: 'BRANCH',
        name: 'Branch',
        description: 'Decide what happens based on an if condition result',
        logoUrl: '/images/piece/branch.svg',
        actions: 1,
        displayName: 'Branch',
        pieceFull: {
            type: 'BRANCH',
            name: 'Branch',
            displayName: 'Branch',
            description: 'Decide what happens based on an if condition result'
        },
        piece: {
            type: 'BRANCH',
            name: 'Branch',
            displayName: 'Branch',
            description: 'Decide what happens based on an if condition result'
        },
        isCore: true
    }
]

export const additionalTriggers = [
    {
        type: 'WEBHOOK',
        name: 'Webhook',
        displayName: 'Webhook',
        description: 'Trigger flow by calling a unique web url',
        logoUrl: '/images/piece/webhook.svg',
        triggers: 1,
        pieceFull: {
            type: 'WEBHOOK',
            name: 'Webhook',
            displayName: 'Webhook',
            description: 'Trigger flow by calling a unique web url'
        },
        piece: {
            type: 'WEBHOOK',
            name: 'Webhook',
            displayName: 'Webhook',
            description: 'Trigger flow by calling a unique web url'
        }
    }
]

const makePieces = (pieces = []) => {
    pieces = [...pieces, ...additionalActions, ...additionalTriggers]
    return pieces
}
export const initialState = {
    localAgent: null,
    localAgents: [],
    agentRunHistory: [],
    agent: null,
    agents: [],
    sidebarType: 'triggers',
    open: false,
    isAuthStarted: false,
    oauth: null,
    isOAuthCompleted: undefined,
    connections: [],
    edgeId: null,
    lastAddedStep: null,
    run: null,
    tools: [],
    pipelines: [],
    lctools: [],
    hoverRowId: null,
    subdomain: null
}

const agentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SUBDOMAIN':
            return {
                ...state,
                subdomain: action.payload
            }
        case 'SET_HOVER_ROW_ID':
            return {
                ...state,
                hoverRowId: action.payload
            }
        case 'UPDATE_LOCAL_AGENT':
            return {
                ...state,
                localAgent: action.payload,
                localAgents: state.localAgents.map((item) => (item.id === action.payload.id ? action.payload : item))
            }
        case 'SET_AGENT_RUN_HISTORY':
            return {
                ...state,
                agentRunHistory: action.payload
            }
        case 'UPDATE_PIECE_SAMPLE_DATA':
            return {
                ...state,
                pieceSampleData: action.payload
            }
        case SET_LOCAL_AGENTS:
            return {
                ...state,
                localAgents: action.payload
            }
        case 'SET_TOOLS':
            return {
                ...state,
                tools: action.payload
            }
        case 'SET_USER_TOOLS':
            return {
                ...state,
                lctools: action.payload
            }
        case 'SET_PIPELINES':
            return {
                ...state,
                pipelines: action.payload
            }
        case SET_LOCAL_AGENT:
            return {
                ...state,
                localAgent: action.payload
            }
        case 'SET_PIECES_FULL':
            return {
                ...state,
                piecesFull: state.piecesFull.map((item) => (item.name === action.payload.name ? action.payload : item))
            }
        case 'REMOVE_OLD_VALUES':
            return {
                ...state,
                agent: null,
                piece: null,
                sidebarType: null,
                open: false,
                providers: {
                    google: {
                        name: 'Google',
                        displayName: 'Google',
                        icon: '/static/icons/ic_google.svg',
                        color: '#DF3E30',
                        clientId: '',
                        clientSecret: '',
                        redirectUrl: '',
                        scope: 'https://www.googleapis.com/auth/dialogflow'
                    }
                },
                isAuthStarted: false,
                oauth: null,
                isOAuthCompleted: undefined,
                edgeId: null,
                lastAddedStep: null,
                run: null
            }
        case SET_RUN_RESULTS:
            return {
                ...state,
                run: action.payload
            }
        case UPDATE_PIECE_VALID_STATE:
            return {
                ...state,
                piece: state.piece.name === action.payload.name ? { ...state.piece, valid: action.payload.validState } : state.piece
            }
        case UPDATE_PIECE:
            return {
                ...state,
                piece: state.piece.name === action.payload.name ? { ...state.piece, ...action.payload } : state.piece
            }
        case LAST_ADDED_STEP:
            return {
                ...state,
                lastAddedStep: action.payload
            }
        case SET_EDGE_ID:
            return {
                ...state,
                edgeId: action.payload
            }
        case SET_CONNECTION:
            return {
                ...state,
                connections: [...state.connections, action.payload]
            }
        case 'SET_LOCAL_CONNECTION':
            return {
                ...state,
                connections: [...state.connections, action.payload]
            }
        case 'SET_AGENT':
            return {
                ...state,
                agent: action.payload
                // agents: action.payload ? state.agents.map((item) => (item.id === action.payload.id ? action.payload : item)) : state.agents
            }
        case SET_PIECE_SIDEBAR_OPEN:
            return {
                ...state,
                open: action.payload
                // lastAddedStep: action.payload ? state.lastAddedStep : null
            }
        case SET_PIECES:
            return {
                ...state,
                pieces: makePieces(action.payload)
            }
        case SET_PIECE:
            return {
                ...state,
                piece: action.payload
            }
        case SET_PIECE_SIDEBAR_TYPE:
            return {
                ...state,
                sidebarType: action.payload
            }
        case SET_OAUTH_STARTED:
            return {
                ...state,
                isAuthStarted: action.payload
            }
        case SET_OAUTH_DATA:
            return {
                ...state,
                oauth: action.payload
            }
        case SET_OAUTH_COMPLETED:
            return {
                ...state,
                isOAuthCompleted: action.payload
            }
        case SET_CONNECTIONS:
            return {
                ...state,
                connections: action.payload
            }
        case 'SET_CONNECTIONS':
            return {
                ...state,
                connections: action.payload
            }
        case SET_AGENTS:
            return {
                ...state,
                agents: action.agents
            }
        default:
            return state
    }
}

export default agentReducer
