export const KNOWLEDGEBASE_INIT_KNOWLEDGEBASE = 'KNOWLEDGEBASE_INIT_KNOWLEDGEBASE'
export const ADD_NEW_KB_DIALOG_OPEN = 'ADD_NEW_KB_DIALOG_OPEN'
export const ALL_KB_DIALOG_OPEN = 'ALL_KB_DIALOG_OPEN'
export const SET_KNOWLEDGEBASES = 'SET_KNOWLEDGEBASES'
export const SET_KNOWLEDGEBASE = 'SET_KNOWLEDGEBASE'
export const ADD_CONTENT_KB_DIALOG_OPEN = 'ADD_CONTENT_KB_DIALOG_OPEN'
export const SHARE_KNOWLEDGEBASE = 'SHARE_KNOWLEDGEBASE'
export const CUSTOMIZE_KNOWLEDGEBASE = 'CUSTOMIZE_KNOWLEDGEBASE'
export const BB_INIT_KNOWLEDGEBASE = 'BB_INIT_KNOWLEDGEBASE'
export const EDIT_CONTENT_KB_DIALOG_OPEN = 'EDIT_CONTENT_KB_DIALOG_OPEN'
export const OPEN_NODE_SIDEBAR = 'OPEN_NODE_SIDEBAR'
export const ADD_NEW_FLOW_OPEN = 'ADD_NEW_FLOW_OPEN'
export const IS_CHATTING = 'IS_CHATTING'
export const SET_KNOWLEDGEBASE_SIDEBAR_TYPE = 'SET_KNOWLEDGEBASE_SIDEBAR_TYPE'
export const SET_EDGE_ID = 'SET_EDGE_ID'
export const SET_PARENT = 'SET_PARENT'
export const UPDATE_KNOWLEDGEBASE = 'UPDATE_KNOWLEDGEBASE'
export const SET_KBS = 'SET_KBS'
export const SET_KB = 'SET_KB'
export const SET_ACTIVE_INTENT = 'SET_ACTIVE_INTENT'
export const SET_KNOWLEDGEBASE_VARIABLES = 'SET_KNOWLEDGEBASE_VARIABLES'
export const SET_EXPANDED = 'SET_EXPANDED'
export const UPDATE_KNOWLEDGEBASE_LOADING = 'UPDATE_KNOWLEDGEBASE_LOADING'
export const SET_ACTIVE_CONDITION = 'SET_ACTIVE_CONDITION'
export const SET_DELETING = 'SET_DELETING'
export const SET_UPDTATING = 'SET_UPDTATING'
export const OPEN_KB_SIDEBAR = 'OPEN_KB_SIDEBAR'
export const SET_KB_SOURCE = 'SET_KB_SOURCE'
export const setKnowledgebase = (knowledgebase) => ({
    type: SET_KNOWLEDGEBASE,
    payload: knowledgebase
})
export const setDeleting = (payload) => ({
    type: SET_DELETING,
    payload
})
export const setUpdating = (payload) => ({
    type: SET_UPDTATING,
    payload
})
export const setKnowledgebases = (knowledgebases) => ({
    type: SET_KNOWLEDGEBASES,
    payload: knowledgebases
})
export const openAddNewKbDialog = (payload) => ({
    type: ADD_NEW_KB_DIALOG_OPEN,
    payload
})
export const openAllKbDialog = (payload) => ({
    type: ALL_KB_DIALOG_OPEN,
    payload
})
