import axios from 'axios'
import { baseURL } from 'store/constant'
// const baseURL = `https://apps.meerkats.ai`
const apiClient = axios.create({
    baseURL: `${baseURL}/api/v1`,
    headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    }
})

apiClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('accessToken')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})
// apiClient.interceptors.response.use(
//     (response) => response,
//     (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// )
export default apiClient
