import PropTypes from 'prop-types'
// @mui
import { styled } from '@mui/material/styles'
import { List, Box, ListSubheader } from '@mui/material'
import { NavListRoot } from './NavList'

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter
    })
}))

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array
}

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
    return (
        <Box {...other}>
            {navConfig.map((menu, i) => (
                <NavListRoot key={i} list={menu} isCollapse={isCollapse} />
            ))}
        </Box>
    )
}
