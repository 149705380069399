import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useMixpanel } from 'react-mixpanel-browser'
import useAuth from 'hooks/useAuth'
// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const NavigationScroll = ({ children }) => {
    const { user } = useAuth()
    const location = useLocation()
    const mixpanel = useMixpanel()
    const { pathname } = location
    const logToMixpanel = (event, data) => {
        if (!mixpanel) {
            console.log('Mixpanel not initialized')
            return
        }
        if (user) {
            mixpanel.identify(user.id ?? user._id)
        }
        mixpanel.track(event, data)
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        const pageName = pathname.replace('/dashboard/', '').replace('/auth/', ' ')
        if (pathname.includes('/dashboard')) {
            if (user) {
                logToMixpanel(`Page ${pageName}`, {
                    pathname,
                    userId: user.id ?? user._id,
                    email: user.email,
                    $distinct_id: user.id ?? user._id,
                    distinct_id: user.id ?? user._id
                })
            }
        } else if (!pathname.includes('/dashboard')) {
            logToMixpanel(`Page ${pageName}`, { pathname, userId: user?.id ?? 'Guest', email: user?.email ?? 'guest' })
        }
    }, [pathname, user])

    return children || null
}

NavigationScroll.propTypes = {
    children: PropTypes.node
}

export default NavigationScroll
