const initialState = {
    apps: []
}
const appsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_APPS':
            return {
                ...state,
                apps: payload
            }
        case 'GET_APPS':
            return {
                ...state,
                apps: payload
            }
        case 'ADD_APP':
            return {
                ...state,
                apps: [...state.apps, payload]
            }
        case 'UPDATE_APP':
            return {
                ...state,
                apps: state.apps.map((app) => (app._id === payload._id ? payload : app))
            }
        case 'DELETE_APP':
            return {
                ...state,
                apps: state.apps.filter((app) => app._id !== payload)
            }
        default:
            return state
    }
}
export default appsReducer
