/* eslint-disable react/display-name */
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { forwardRef } from 'react'
// @mui
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
    <HelmetProvider>
        <Helmet>
            <title>{`${title} `}</title>
            {meta}
        </Helmet>

        <Box ref={ref} {...other}>
            {children}
        </Box>
    </HelmetProvider>
))

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    meta: PropTypes.node
}

export default Page
