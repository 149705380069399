/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function DashboardLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { pathname } = useLocation()
    const [showSidebar, setShowSidebar] = useState(true)
    useEffect(() => {
        if (pathname.includes('/dashboard/usertable/')) {
            const arr = pathname
                .split('/')
                .map((item) => item.trim())
                .filter((item) => item && item.length > 5).length
            if (arr === 3) {
                setShowSidebar(false)
            } else {
                setShowSidebar(true)
            }
        } else {
            setShowSidebar(true)
        }
    }, [pathname])

    return (
        <>
            <div>
                <Dialog open={sidebarOpen} onClose={setSidebarOpen} className='relative z-50 lg:hidden'>
                    <DialogBackdrop
                        transition
                        className='fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0'
                    />

                    <div className='fixed inset-0 flex'>
                        <DialogPanel
                            transition
                            className='relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full'
                        >
                            <TransitionChild>
                                <div className='absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0'>
                                    <button type='button' onClick={() => setSidebarOpen(false)} className='-m-2.5 p-2.5'>
                                        <span className='sr-only'>Close sidebar</span>
                                        <XMarkIcon aria-hidden='true' className='h-6 w-6 text-white' />
                                    </button>
                                </div>
                            </TransitionChild>
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <Sidebar />
                        </DialogPanel>
                    </div>
                </Dialog>

                {/* Static sidebar for desktop */}
                {showSidebar ? (
                    <div className='hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-64 lg:flex-col'>
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <Sidebar />
                    </div>
                ) : null}

                <div className='sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden'>
                    <button type='button' onClick={() => setSidebarOpen(true)} className='-m-2.5 p-2.5 text-gray-700 lg:hidden'>
                        <span className='sr-only'>Open sidebar</span>
                        <Bars3Icon aria-hidden='true' className='h-6 w-6' />
                    </button>
                    <div className='flex-1 text-sm font-semibold leading-6 text-gray-900'>Dashboard</div>
                    <a href='#'>
                        <span className='sr-only'>Your profile</span>
                        <img
                            alt=''
                            src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                            className='h-8 w-8 rounded-full bg-gray-50'
                        />
                    </a>
                </div>

                <main
                    className={classNames(
                        showSidebar ? 'pb-10 pt-5 lg:pl-64 bg-white h-screen px-4 sm:px-6 lg:px-8' : 'bg-white h-screen w-screen'
                    )}
                >
                    <div className='h-full w-full'>
                        {' '}
                        <Outlet />
                    </div>
                </main>
            </div>
        </>
    )
}
