export const BB_DOCUMENTS = 'BB_DOCUMENTS'
export const BB_FAQS = 'BB_FAQS'
export const BB_SITEMAP = 'BB_SITEMAP'
export const BB_TEXT = 'BB_TEXT'
export const BB_WEBSITE = 'BB_WEBSITE'
export const BB_STEP = 'BB_STEP'
export const BB_BOT_NAME = 'BB_BOT_NAME'
export const BB_COMPANY_NAME = 'BB_COMPANY_NAME'
export const BB_BOT_ID = 'BB_BOT_ID'
export const BB_INIT_BOT = 'BB_INIT_BOT'
export const ADD_NEW_KB_DIALOG_OPEN = 'ADD_NEW_KB_DIALOG_OPEN'
export const ALL_KB_DIALOG_OPEN = 'ALL_KB_DIALOG_OPEN'
export const SET_CHATBOTS = 'SET_CHATBOTS'
export const ADD_CONTENT_KB_DIALOG_OPEN = 'ADD_CONTENT_KB_DIALOG_OPEN'
export const SHARE_CHATBOT = 'SHARE_CHATBOT'
export const CUSTOMIZE_CHATBOT = 'CUSTOMIZE_CHATBOT'
export const EDIT_CONTENT_KB_DIALOG_OPEN = 'EDIT_CONTENT_KB_DIALOG_OPEN'

export const setDocuments = (documents) => ({
    type: BB_DOCUMENTS,
    payload: documents
})

export const setFAQs = (faqs) => ({
    type: BB_FAQS,
    payload: faqs
})

export const setSitemap = (sitemap) => ({
    type: BB_SITEMAP,
    payload: sitemap
})

export const setText = (text) => ({
    type: BB_TEXT,
    payload: text
})

export const setWebsite = (website) => ({
    type: BB_WEBSITE,
    payload: website
})

export const setStep = (step) => ({
    type: BB_STEP,
    payload: step
})

export const setBotName = (botName) => ({
    type: BB_BOT_NAME,
    payload: botName
})

export const setCompanyName = (companyName) => ({
    type: BB_COMPANY_NAME,
    payload: companyName
})

export const setBot = (bot) => ({
    type: BB_BOT_ID,
    payload: bot
})
export const openAddNewKbDialog = (payload) => ({
    type: ADD_NEW_KB_DIALOG_OPEN,
    payload
})
export const openAllKbDialog = (payload) => ({
    type: ALL_KB_DIALOG_OPEN,
    payload
})
