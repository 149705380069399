import axios from 'axios'
import { baseURL } from 'store/constant'
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: `${baseURL}/api/v1`
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export default axiosInstance
