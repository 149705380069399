import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
import { CssBaseline, LinearProgress } from '@mui/material'
import { ChartStyle } from './components/chart'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
// routing
import Routes from 'routes'
import useAuth from 'hooks/useAuth'

// defaultTheme
import ErrorBoundary from 'ErrorBoundary'
// project imports
import NavigationScroll from 'layout/NavigationScroll'
import Toast from 'ui-component/Toast'

// ==============================|| APP ||============================== //

const App = () => {
    const { isInitialized } = useAuth()

    return (
        <ThemeProvider>
            <ThemeSettings>
                <ErrorBoundary>
                    <CssBaseline />
                    <NavigationScroll>
                        <ProgressBarStyle />
                        <Toast />
                        <ChartStyle />
                        <ScrollToTop />
                        {isInitialized ? <Routes /> : <LinearProgress />}
                    </NavigationScroll>
                </ErrorBoundary>
            </ThemeSettings>
        </ThemeProvider>
    )
}

export default App
