import {
    BB_DOCUMENTS,
    BB_FAQS,
    BB_SITEMAP,
    BB_TEXT,
    BB_WEBSITE,
    BB_STEP,
    BB_BOT_NAME,
    BB_COMPANY_NAME,
    BB_BOT_ID,
    BB_INIT_BOT,
    SET_CHATBOTS,
    ADD_NEW_KB_DIALOG_OPEN,
    ALL_KB_DIALOG_OPEN,
    ADD_CONTENT_KB_DIALOG_OPEN,
    SHARE_CHATBOT,
    CUSTOMIZE_CHATBOT,
    EDIT_CONTENT_KB_DIALOG_OPEN
} from '../basicBotActions'

export const initialState = {
    website: '',
    sitemap: '',
    documents: [],
    text: '',
    faqs: [],
    step: 0,
    botName: '',
    companyName: '',
    botId: '',
    bot: null,
    editBot: null,
    chatbots: [],
    allKbDialogOpen: false,
    addNewKbDialogOpen: false,
    editKbDialogOpen: false,
    shareBotDialogOpen: false,
    customizeBotDialogOpen: false
}

const basicBotReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                editKbDialogOpen: !!action.payload,
                editBot: action.payload ?? null
            }
        case ALL_KB_DIALOG_OPEN:
            return {
                ...state,
                allKbDialogOpen: action.payload
            }
        case SHARE_CHATBOT:
            return {
                ...state,
                shareBotDialogOpen: action.payload
            }
        case CUSTOMIZE_CHATBOT:
            return {
                ...state,
                customizeBotDialogOpen: action.payload
            }
        case ADD_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: !!action.payload,
                bot: action.payload ?? null
            }
        case ADD_NEW_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: action.payload,
                editBot: null,
                editKbDialogOpen: false
            }
        case SET_CHATBOTS:
            return {
                ...state,
                chatbots: action.chatbots
            }
        case BB_WEBSITE:
            return {
                ...state,
                website: action.payload
            }
        case BB_STEP:
            return {
                ...state,
                step: action.payload
            }
        case BB_SITEMAP:
            return {
                ...state,
                sitemap: action.payload
            }
        case BB_DOCUMENTS:
            return {
                ...state,
                documents: [...state.documents, ...action.payload]
            }
        case BB_TEXT:
            return {
                ...state,
                text: action.payload
            }
        case BB_FAQS:
            return {
                ...state,
                faqs: [...state.faqs, action.payload]
            }
        case BB_BOT_NAME:
            return {
                ...state,
                botName: action.payload
            }
        case BB_COMPANY_NAME:
            return {
                ...state,
                companyName: action.payload
            }
        case BB_BOT_ID:
            return {
                ...state,
                botId: action.payload?.id,
                bot: action.payload,
                website: action.payload?.website ?? '',
                sitemap: action.payload?.sitemap ?? '',
                botName: action.payload?.name ?? '',
                companyName: action.payload?.company ?? ''
            }
        case BB_INIT_BOT:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default basicBotReducer
