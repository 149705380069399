import { pxToRem, responsiveFontSizes } from '../utils/getFontValue'

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Poppins, Public Sans, sans-serif'

const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        lineHeight: 80 / 64,
        fontSize: pxToRem(32),
        letterSpacing: 2,
        ...responsiveFontSizes({ sm: 32, md: 32, lg: 32 })
    },
    h2: {
        fontWeight: 700,
        lineHeight: 64 / 48,
        fontSize: pxToRem(24),
        ...responsiveFontSizes({ sm: 24, md: 24, lg: 24 })
    },
    h3: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(18),
        ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
    },
    h4: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
    },
    h5: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(13),
        ...responsiveFontSizes({ sm: 13, md: 13, lg: 13 })
    },
    h6: {
        fontWeight: 700,
        lineHeight: 28 / 18,
        fontSize: pxToRem(10),
        ...responsiveFontSizes({ sm: 10, md: 10, lg: 10 })
    },
    subtitle1: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(14)
    },
    subtitle2: {
        fontWeight: 600,
        lineHeight: 22 / 14,
        fontSize: pxToRem(12)
    },
    body1: {
        lineHeight: 1.5,
        fontSize: pxToRem(14)
    },
    body2: {
        lineHeight: 22 / 14,
        fontSize: pxToRem(12)
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(10)
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(10),
        textTransform: 'uppercase'
    },
    button: {
        fontWeight: 500,
        lineHeight: 1.5,
        fontSize: pxToRem(14),
        textTransform: 'capitalize'
    }
}

export default typography
