import client from './client'

export default {
    listSubdomains: () => client.get('/subdomains'),
    getSubdomain: (id) => client.get(`/subdomains/${id}`),
    createSubdomain: (payload) => client.post('/subdomains', payload),
    updateSubdomain: (id, payload) => client.put(`/subdomains/${id}`, payload),
    deleteSubdomain: (id) => client.delete(`/subdomains/${id}`),
    getSubdomainByName: (name) => client.get(`/subdomains/name/${name}`)
}
