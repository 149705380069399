import { combineReducers } from 'redux'

// reducer import
import customizationReducer from './reducers/customizationReducer'
import notifierReducer from './reducers/notifierReducer'
import dialogReducer from './reducers/dialogReducer'
import sessionReducer from '../views/auth/store/Reducer'
import basicBotReducer from './reducers/basicBotReducers'
import botReducer from './reducers/botReducer'
import kbReducer from './reducers/kbReducer'
import agentReducer from './reducers/agentReducers'
import storage from 'redux-persist/lib/storage'
import kanbanReducer from './reducers/kanban'
import toastReducers from '../views/auth/store/Reducer'
import lcFlowReducer from './reducers/lcFlowReducer'
import lcToolsReducer from './reducers/lcTools'
import tableReducer from './reducers/tableReducer'
import aiPagesReducer from './reducers/aiPagesReducer'
import datagridReducer from './reducers/datagridReducer'
import appsReducer from './reducers/appsReducer'
// ==============================|| COMBINE REDUCER ||============================== //
const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: []
}
const rootReducer = combineReducers({
    lcTools: lcToolsReducer,
    customization: customizationReducer,
    notifier: notifierReducer,
    dialog: dialogReducer,
    session: sessionReducer,
    basicBot: basicBotReducer,
    bot: botReducer,
    lcflow: lcFlowReducer,
    kb: kbReducer,
    kanban: kanbanReducer,
    toast: toastReducers,
    agent: agentReducer,
    table: tableReducer,
    aiPages: aiPagesReducer,
    datagrid: datagridReducer,
    apps: appsReducer
})

export { rootReducer, rootPersistConfig }
