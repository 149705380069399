export const OPEN_SIDEBAR = 'OPEN_SIDEBAR'
export const SET_ACTIVE_TEAM_ID = 'SET_ACTIVE_TEAM_ID'
export const SET_PREBUILT_TOOLS = 'SET_PREBUILT_TOOLS'
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH'

export const LC_FLOW_INIT_LC_FLOW = 'LC_FLOW_INIT_LC_FLOW'
export const ADD_NEW_KB_DIALOG_OPEN = 'ADD_NEW_KB_DIALOG_OPEN'
export const ALL_KB_DIALOG_OPEN = 'ALL_KB_DIALOG_OPEN'
export const SET_LC_FLOWS = 'SET_LC_FLOWS'
export const SET_LC_FLOW = 'SET_LC_FLOW'
export const ADD_CONTENT_KB_DIALOG_OPEN = 'ADD_CONTENT_KB_DIALOG_OPEN'
export const SHARE_LC_FLOW = 'SHARE_LC_FLOW'
export const CUSTOMIZE_LC_FLOW = 'CUSTOMIZE_LC_FLOW'
export const BB_INIT_LC_FLOW = 'BB_INIT_LC_FLOW'
export const EDIT_CONTENT_KB_DIALOG_OPEN = 'EDIT_CONTENT_KB_DIALOG_OPEN'
export const OPEN_NODE_SIDEBAR = 'OPEN_NODE_SIDEBAR'
export const ADD_NEW_FLOW_OPEN = 'ADD_NEW_FLOW_OPEN'
export const IS_CHATTING = 'IS_CHATTING'
export const SET_LC_FLOW_SIDEBAR_TYPE = 'SET_LC_FLOW_SIDEBAR_TYPE'
export const SET_EDGE_ID = 'SET_EDGE_ID'
export const SET_PARENT = 'SET_PARENT'
export const UPDATE_LC_FLOW = 'UPDATE_LC_FLOW'
export const SET_KBS = 'SET_KBS'
export const SET_KB = 'SET_KB'
export const SET_ACTIVE_INTENT = 'SET_ACTIVE_INTENT'
export const SET_LC_FLOW_VARIABLES = 'SET_LC_FLOW_VARIABLES'
export const SET_EXPANDED = 'SET_EXPANDED'
export const UPDATE_LC_FLOW_LOADING = 'UPDATE_LC_FLOW_LOADING'
export const SET_ACTIVE_CONDITION = 'SET_ACTIVE_CONDITION'
export const SET_DELETING = 'SET_DELETING'
export const SET_UPDTATING = 'SET_UPDTATING'
export const ANY_UNSAVED_CHANGES = 'ANY_UNSAVED_CHANGES'
export const SET_ACTIVE_FLOW_ID = 'SET_ACTIVE_FLOW_ID'
export const setLcFlow = (lcflow) => ({
    type: SET_LC_FLOW,
    payload: lcflow
})
export const setDeleting = (payload) => ({
    type: SET_DELETING,
    payload
})
export const setUpdating = (payload) => ({
    type: SET_UPDTATING,
    payload
})
export const setLcFlows = (lcflows) => ({
    type: SET_LC_FLOWS,
    payload: lcflows
})
export const openAddNewKbDialog = (payload) => ({
    type: ADD_NEW_KB_DIALOG_OPEN,
    payload
})
export const openAllKbDialog = (payload) => ({
    type: ALL_KB_DIALOG_OPEN,
    payload
})
