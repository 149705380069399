import {
    BB_INIT_KNOWLEDGEBASE,
    SET_KNOWLEDGEBASES,
    SET_KNOWLEDGEBASE,
    ADD_NEW_KB_DIALOG_OPEN,
    ALL_KB_DIALOG_OPEN,
    ADD_CONTENT_KB_DIALOG_OPEN,
    SHARE_KNOWLEDGEBASE,
    CUSTOMIZE_KNOWLEDGEBASE,
    EDIT_CONTENT_KB_DIALOG_OPEN,
    OPEN_NODE_SIDEBAR,
    ADD_NEW_FLOW_OPEN,
    IS_CHATTING,
    SET_KNOWLEDGEBASE_SIDEBAR_TYPE,
    SET_EDGE_ID,
    SET_PARENT,
    UPDATE_KNOWLEDGEBASE,
    SET_KBS,
    SET_KB,
    SET_ACTIVE_INTENT,
    SET_KNOWLEDGEBASE_VARIABLES,
    SET_EXPANDED,
    UPDATE_KNOWLEDGEBASE_LOADING,
    SET_ACTIVE_CONDITION,
    SET_DELETING,
    SET_UPDTATING,
    SET_KB_SOURCE
} from '../kbActions'

export const initialState = {
    knowledgebase: null,
    editKnowledgebase: null,
    knowledgebases: [],
    knowledgeBases: [],
    allKbDialogOpen: false,
    addNewKbDialogOpen: false,
    editKbDialogOpen: false,
    shareKnowledgebaseDialogOpen: false,
    customizeKnowledgebaseDialogOpen: false,
    sidebarOpen: false,
    sidebarData: null,
    addNewFlowOpen: false,
    isChatting: false,
    edgeId: null,
    lastAddedStep: null,
    sidebarType: null,
    parent: null,
    updateKnowledgebaseTime: null,
    activeIntent: 0,
    knowledgebaseVariables: {},
    expanded: [],
    updateKnowledgebaseLoading: null,
    activeCondition: null,
    deleting: null,
    updating: null
}

const kbReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_KB_SOURCE:
            return {
                ...state,
                knowledgebase: {
                    ...state.knowledgebase,
                    sources: [...(state.knowledgebase?.sources ?? []), action.payload]
                }
            }
        case SET_DELETING:
            return {
                ...state,
                deleting: action.payload
            }
        case SET_UPDTATING:
            return {
                ...state,
                updating: action.payload
            }
        case SET_ACTIVE_CONDITION:
            return {
                ...state,
                activeCondition: action.id
            }
        case UPDATE_KNOWLEDGEBASE_LOADING:
            return {
                ...state,
                updateKnowledgebaseLoading: action.payload
            }
        case SET_EXPANDED:
            return {
                ...state,
                expanded: action.payload
            }
        case SET_KNOWLEDGEBASE_VARIABLES:
            return {
                ...state,
                knowledgebaseVariables: { ...state.knowledgebaseVariables, ...action.payload }
            }
        case SET_ACTIVE_INTENT:
            return {
                ...state,
                activeIntent: action.payload
            }
        case SET_KBS:
            return {
                ...state,
                knowledgeBases: action.payload
            }
        case SET_KB:
            return {
                ...state,
                knowledgebase: action.knowledgebase
            }
        case 'REMOVE_OLD_VALUES':
            return {
                ...state,
                activeIntent: 0,
                knowledgebase: null,
                editKnowledgebase: null,
                allKbDialogOpen: false,
                addNewKbDialogOpen: false,
                editKbDialogOpen: false,
                shareKnowledgebaseDialogOpen: false,
                customizeKnowledgebaseDialogOpen: false,
                sidebarOpen: false,
                sidebarData: null,
                addNewFlowOpen: false,
                isChatting: false,
                edgeId: null,
                lastAddedStep: null,
                sidebarType: null,
                parent: null,
                updateKnowledgebaseTime: null
            }

        case UPDATE_KNOWLEDGEBASE:
            return {
                ...state,
                updateKnowledgebaseTime: action.payload
            }
        case SET_PARENT:
            return {
                ...state,
                parent: action.payload
            }
        case SET_EDGE_ID:
            return {
                ...state,
                edgeId: action.payload
            }
        case SET_KNOWLEDGEBASE_SIDEBAR_TYPE:
            return {
                ...state,
                sidebarType: action.payload
            }
        case IS_CHATTING:
            return {
                ...state,
                isChatting: action.payload,
                sidebarData: null,
                sidebarOpen: false,
                addNewFlowOpen: false,
                sidebarType: null,
                parent: null
            }
        case ADD_NEW_FLOW_OPEN:
            return {
                ...state,
                addNewFlowOpen: action.payload,
                sidebarData: null,
                sidebarOpen: false,
                isChatting: false
            }
        case EDIT_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                editKbDialogOpen: !!action.payload,
                editKnowledgebase: action.payload ?? null
            }
        case OPEN_NODE_SIDEBAR:
            return {
                ...state,
                sidebarOpen: !!action.payload,
                sidebarData: action.payload ?? null,
                isChatting: false,
                addNewFlowOpen: false
            }
        case ALL_KB_DIALOG_OPEN:
            return {
                ...state,
                allKbDialogOpen: action.payload
            }
        case SET_KNOWLEDGEBASE:
            return {
                ...state,
                knowledgebase: action.knowledgebase
            }
        case SHARE_KNOWLEDGEBASE:
            return {
                ...state,
                shareKnowledgebaseDialogOpen: action.payload
            }
        case CUSTOMIZE_KNOWLEDGEBASE:
            return {
                ...state,
                customizeKnowledgebaseDialogOpen: action.payload
            }
        case ADD_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: !!action.payload,
                knowledgebase: action.payload ?? null
            }
        case ADD_NEW_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: action.payload,
                editKnowledgebase: null,
                editKbDialogOpen: false
            }
        case SET_KNOWLEDGEBASES:
            return {
                ...state,
                knowledgebases: action.knowledgebases
            }
        case BB_INIT_KNOWLEDGEBASE:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default kbReducer
