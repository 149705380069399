import PropTypes from 'prop-types'

import { Navigate, useLocation } from 'react-router-dom'
// hooks
import useAuth from '../hooks/useAuth'
// routes
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node
}

export default function GuestGuard({ children }) {
    let { isAuthenticated, user } = useAuth()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const isGuest = user?.role === 'guest'
    useEffect(() => {
        if (!isGuest && isAuthenticated && !pathname.includes('dashboard') && !pathname.includes('playground')) {
            navigate('/dashboard')
        }
    }, [isAuthenticated, pathname])

    return <>{children}</>
}
