import { Link, useLocation } from 'react-router-dom'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import { Box, AppBar, Toolbar, Container } from '@mui/material'
// hooks
import useOffSetTop from '../../hooks/useOffSetTop'
import useResponsive from '../../hooks/useResponsive'
// utils
import cssStyles from '../../utils/cssStyles'
// config
import { HEADER } from '../../config'
// components
import Logo from '../../components/Logo'
//
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import navConfig from './MenuConfig'
import useAuth from 'hooks/useAuth'

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT
    }
}))

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.customShadows.z8
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

    const theme = useTheme()

    const { pathname } = useLocation()

    const isDesktop = useResponsive('up', 'md')
    const { isAuthenticated, user } = useAuth()
    const isAdmin = user?.role === 'admin'

    const isHome = pathname === '/'
    const isGuest = user?.role === 'guest'

    return (
        <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
            <ToolbarStyle
                disableGutters
                sx={{
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 }
                    })
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Logo isPublic />
                    <Box sx={{ flexGrow: 1 }} />

                    {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
                    {!isGuest && isAuthenticated && isAdmin ? (
                        <Link
                            style={{
                                color: '#260666'
                            }}
                            to='/dashboard/prompt-chains'
                        >
                            Dashboard
                        </Link>
                    ) : !isGuest && isAuthenticated && !isAdmin ? (
                        <Link
                            style={{
                                color: '#260666'
                            }}
                            to='/dashboard/prompt-chains'
                        >
                            Dashboard
                        </Link>
                    ) : (
                        <Link
                            style={{
                                color: '#260666'
                            }}
                            to='/auth/login'
                        >
                            Login
                        </Link>
                    )}

                    {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
                </Container>
            </ToolbarStyle>

            {isOffset && <ToolbarShadowStyle />}
        </AppBar>
    )
}
