export const LOADING = 'LOADING'
export const BACKDROP = 'BACKDROP'
export const LOGOUT = 'LOGOUT'
export const SET_TOAST = 'SET_TOAST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED'
export const FORGET_EMAIL_SENT = 'FORGET_EMAIL_SENT'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const SET_ASSESSMENT = 'SET_ASSESSMENT'
export const SET_TOKEN_VERIFICATION_STATUS = 'SET_TOKEN_VERIFICATION_STATUS'
