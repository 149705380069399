/* eslint-disable no-case-declarations */
import {
    SET_COLUMN_AI_CONFIG,
    SET_AI_COLUMN_EDITOR_FULL_SCREEN,
    IS_AI_COLUMN_EDITOR_IN_EDIT_MODE,
    SET_COLUMN_AI_CONFIG_OPEN,
    SET_TABLE,
    SET_ROWS,
    SET_COLUMNS,
    ADD_ROW,
    DELETE_ROW,
    UPDATE_ROW,
    ADD_COLUMN,
    SET_COLUMN_TYPE,
    OPEN_CELL_LOGS,
    OPEN_ROW_DETAILS,
    ADD_NEW_NORMAL_COLUMN,
    SET_ANCHOR_EL,
    OPEN_CELL_DETAILS,
    SET_GRID_COLUMNS,
    ADD_COLUMN_AFTER,
    SET_CELLS,
    SET_CELLS_GENERATIONS
} from '../tableActions'
const initialColumn = {
    title: '',
    prompt: '',
    datatype: 'string',
    inputColumns: [],
    tool: null,
    isAi: false,
    systemPrompt: null,
    isMultiOutput: false,
    showInAdvanced: true
}
export const initialState = {
    latestRuns: [],
    tables: [],
    search: '',
    table: null,
    rows: [],
    cells: [],
    columns: [],
    cellGenerations: [],
    columnAiConfig: initialColumn,
    isAiColumnEditorfullScreen: false,
    isAiColumnEditorInEditMode: false,
    isColumnAiConfigOpen: false,
    isCellLogsOpen: false,
    cellLogsData: null,
    isRowDetailsOpen: false,
    rowDetailsData: null,
    anchorEl: null,
    cellDetailsData: null,
    addColumnAfter: null,
    blinking: false,
    stopRunAllLoading: false,
    disabledWhenRunAll: false,
    runAllId: null,
    runAllCompleted: false,
    showCellStatus: false,
    row: null,
    disableStopAllbtn: false,
    refreshRowTime: null,
    runWbData: null,
    inputCells: [],
    showOnlyMyWb: false,
    activeCell: null,
    showInput: true,
    templates: [],
    cellTitles: [],
    cell: null,
    isDrawerOpen: false,
    panelTitle: null
}

const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CELLS_FOR_DG':
            return {
                ...state,
                cells: action.payload
            }
        case 'SET_PANEL_TITLE':
            return {
                ...state,
                panelTitle: action.payload
            }
        case 'UPDATE_CELL':
            return {
                ...state,
                cell: action.payload,
                cells: state.cells.map((cell) => (cell.uiId === action.payload.uiId ? action.payload : cell))
            }
        case 'EDIT_CELL_PROMPT':
            return {
                ...state,
                cell: action.payload
            }
        case 'EDIT_CELL':
            return {
                ...state,
                cell: action.payload,
                panelTitle: 'Cell Configuration',
                isDrawerOpen: true
            }
        case 'CLOSE_DRAWER':
            return {
                ...state,
                isDrawerOpen: false,
                cell: null,
                panelTitle: null
            }
        case 'ADD_TOOL':
            return {
                ...state,
                cell: action.payload,
                isDrawerOpen: true,
                panelTitle: 'Add a Tool'
            }

        case 'EDIT_TOOL':
            return {
                ...state,
                cell: action.payload,
                panelTitle: 'Tool Configuration',
                isDrawerOpen: true
            }

        case 'RENAME_CELL':
            return {
                ...state,
                cells: state.cells.map((cell) => (cell.uiId === action.payload.uiId ? { ...cell, title: action.payload.title } : cell))
            }
        case 'DELETE_CELL':
            return {
                ...state,
                cells: state.cells.filter((cell) => cell.uiId !== action.payload)
            }
        case 'SAVE_CELL':
            const findCell1 = state.cells.find((cell) => cell.uiId === action.payload.uiId)
            if (findCell1) {
                const cells = state.cells.map((cell) => (cell.uiId === action.payload.uiId ? action.payload : cell))
                const cellTitles = [...new Set(cells.map((cell) => cell.title))]
                return {
                    ...state,
                    cells: [...new Set(cells)],
                    cellTitles
                }
            } else {
                const index = action.payload.after + 1
                let cells = state.cells
                const beforeCells = state.cells.slice(0, index)
                const afterCells = state.cells.slice(index).map((cell) => ({ ...cell, index: cell.index + 1 }))
                cells = [...beforeCells, action.payload, ...afterCells]
                const cellTitles = [...new Set(cells.map((cell) => cell.title))]
                return {
                    ...state,
                    cells: [...new Set(cells)],
                    cellTitles
                }
            }
        case 'SET_TEMPLATES':
            return {
                ...state,
                templates: action.payload
            }
        case 'SET_LATEST_RUNS':
            return {
                ...state,
                latestRuns: action.payload
            }
        case 'UPDATE_TABLE_NAME':
            return {
                ...state,
                table: state.table.id === action.payload.id ? { ...state.table, name: action.payload.name } : state.table,
                latestRuns: [{ tableId: action.payload.id, tableName: action.payload.name, updatedAt: new Date() }, ...state.latestRuns]
            }
        // case 'SET_SHOW_INPUT':
        //     return {
        //         ...state,
        //         showInput: action.payload
        //     }
        case 'SCROLL_TO_CELL':
            return {
                ...state,
                activeCell: action.payload
            }
        case 'SET_SHOW_ONLY_MY_WB':
            return {
                ...state,
                showOnlyMyWb: action.payload
            }
        case 'SET_TABLES':
            return {
                ...state,
                tables: action.payload
            }
        case 'SET_SEARCH':
            return {
                ...state,
                search: action.payload
            }
        case 'SET_INPUT_CELL':
            return {
                ...state,
                columnAiConfig: { ...state.columnAiConfig, inputColumns: action.payload }
            }
        case 'setRunWbData':
            return {
                ...state,
                runWbData: action.payload
            }
        case 'refreshRow':
            return {
                ...state,
                refreshRowTime: action.payload
            }
        case 'disableStopAllbtn':
            return {
                ...state,
                disableStopAllbtn: action.payload
            }
        case 'setRow':
            return {
                ...state,
                row: action.payload
            }
        case 'showCellStatus':
            return {
                ...state,
                showCellStatus: action.payload
            }
        case 'runAllCompleted':
            return {
                ...state,
                runAllCompleted: action.payload
            }
        case 'setRunAllId':
            return {
                ...state,
                runAllId: action.payload
            }
        case 'disabledWhenRunAll':
            return {
                ...state,
                disabledWhenRunAll: action.payload
            }
        case 'stopRunAllLoading':
            return {
                ...state,
                stopRunAllLoading: action.payload
            }
        case 'SET_BLINKING':
            return {
                ...state,
                blinking: action.payload
            }
        case SET_CELLS_GENERATIONS:
            return {
                ...state,
                cellGenerations: action.payload
            }
        case SET_CELLS:
            const cellTitles = (action.payload ?? []).map((cell) => cell.title)
            return {
                ...state,
                cells: action.payload,
                cellTitles
            }
        case ADD_COLUMN_AFTER:
            return {
                ...state,
                addColumnAfter: action.payload
            }
        case OPEN_CELL_DETAILS:
            return {
                ...state,
                cellDetailsData: action.payload
            }
        case ADD_NEW_NORMAL_COLUMN:
            return {
                ...state,
                isAiColumnEditorfullScreen: false,
                isAiColumnEditorInEditMode: false,
                isColumnAiConfigOpen: true,
                columnAiConfig: {
                    ...initialColumn,
                    isAi: false
                }
            }
        case SET_COLUMN_AI_CONFIG:
            return {
                ...state,
                columnAiConfig: { ...state.columnAiConfig, ...action.payload }
            }
        case SET_AI_COLUMN_EDITOR_FULL_SCREEN:
            return {
                ...state,
                isAiColumnEditorfullScreen: action.payload
            }
        case IS_AI_COLUMN_EDITOR_IN_EDIT_MODE:
            return {
                ...state,
                isAiColumnEditorInEditMode: action.payload
            }
        case SET_COLUMN_AI_CONFIG_OPEN:
            return {
                ...state,
                isAiColumnEditorfullScreen: !action.payload ? false : state.isAiColumnEditorfullScreen,
                isAiColumnEditorInEditMode: !action.payload ? null : state.isAiColumnEditorInEditMode,
                anchorEl: !action.payload ? null : state.anchorEl,
                isColumnAiConfigOpen: action.payload,
                columnAiConfig: action.payload ? { ...initialColumn, isAi: true } : null
            }
        case SET_TABLE:
            return {
                ...state,
                table: action.payload
            }
        case SET_ROWS:
            return {
                ...state,
                rows: action.payload
            }
        case SET_GRID_COLUMNS:
            return {
                ...state,
                columns: action.payload
            }
        case SET_COLUMNS:
            return {
                ...state,
                columns: action.payload
            }
        case ADD_ROW:
            return {
                ...state,
                rows: [...state.rows, action.payload]
            }
        case DELETE_ROW:
            return {
                ...state,
                rows: state.rows.filter((row) => row.id !== action.payload)
            }
        case UPDATE_ROW:
            return {
                ...state,
                rows: state.rows.map((row) => (row.id === action.payload.id ? action.payload : row))
            }
        case ADD_COLUMN:
            return {
                ...state,
                columns: [...state.columns, action.payload]
            }
        case SET_COLUMN_TYPE:
            return {
                ...state,
                columnAiConfig: { ...state.columnAiConfig, isAi: action.payload == 'ai' }
            }
        case OPEN_CELL_LOGS:
            return {
                ...state,
                isCellLogsOpen: !!action.payload,
                cellLogsData: action.payload
            }
        case OPEN_ROW_DETAILS:
            return {
                ...state,
                isRowDetailsOpen: !!action.payload,
                rowDetailsData: action.payload
            }
        case SET_ANCHOR_EL:
            return {
                ...state,
                anchorEl: action.payload
            }
        default:
            return state
    }
}

export default tableReducer
