import App from './App'
import './assets/scss/global.css'
import './assets/scss/proseMirror.css'
import { store } from 'store'
import { createRoot } from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'assets/scss/style.scss'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import ConfirmContextProvider from 'store/context/ConfirmContextProvider'
import { ReactFlowContext } from 'store/context/ReactFlowContext'
import { AuthProvider } from 'store/context/JWTContext'
import { SettingsProvider } from './contexts/SettingsContext'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'
import { MixpanelProvider } from 'react-mixpanel-browser'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only'
})
const container = document.getElementById('root')
const root = createRoot(container)
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

// [OPTIONAL] Custom options to pass to `mixpanel.init()`.
const MIXPANEL_CONFIG = {
    // track_pageview: true
}
root.render(
    <>
        <PostHogProvider client={posthog}>
            <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
                <AuthProvider>
                    <HelmetProvider>
                        <Provider store={store}>
                            <SettingsProvider>
                                <CollapseDrawerProvider>
                                    <BrowserRouter>
                                        <SnackbarProvider>
                                            <ConfirmContextProvider>
                                                <ReactFlowContext>
                                                    <App />
                                                </ReactFlowContext>
                                            </ConfirmContextProvider>
                                        </SnackbarProvider>
                                    </BrowserRouter>
                                </CollapseDrawerProvider>
                            </SettingsProvider>
                        </Provider>
                    </HelmetProvider>
                </AuthProvider>
            </MixpanelProvider>
        </PostHogProvider>
    </>
)
