import { createSlice } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
// utils
import axios from '../../utils/axios'
//
import { dispatch } from '../index'

// ----------------------------------------------------------------------
const daa = {
    board: {
        columns: {
            '1-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1': {
                id: '1-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                name: 'To Do',
                taskIds: [
                    '1-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                    '2-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                    '3-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3'
                ]
            },
            '2-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2': {
                id: '2-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                name: 'In Progress',
                taskIds: ['4-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4', '5-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5']
            },
            '3-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3': {
                id: '3-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
                name: 'Ready To Test',
                taskIds: []
            },
            '4-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4': {
                id: '4-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
                name: 'Done',
                taskIds: ['6-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6']
            }
        },
        tasks: {
            '1-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1': {
                id: '1-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                due: [null, null],
                status: 'To Do',
                labels: [],
                comments: [],
                assignee: [],
                priority: 'low',
                attachments: [],
                reporter: {
                    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
                    name: 'Angelique Morse',
                    avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_17.jpg'
                },
                name: 'Complete Project Proposal',
                description:
                    'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.'
            },
            '2-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2': {
                id: '2-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                due: [1697085686399, 1697172086399],
                status: 'To Do',
                labels: ['Technology'],
                comments: [
                    {
                        id: '7b3d0f80-c132-466d-9b85-e68050cda375',
                        name: 'Jayvion Simon',
                        createdAt: '2023-10-10T04:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg',
                        messageType: 'text',
                        message: 'The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.'
                    }
                ],
                assignee: [
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                        name: 'Jayvion Simon',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg'
                    }
                ],
                priority: 'hight',
                attachments: [
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_12.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_13.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_14.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_15.jpg'
                ],
                reporter: {
                    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
                    name: 'Angelique Morse',
                    avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_17.jpg'
                },
                name: 'Conduct Market Research',
                description:
                    'Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.'
            },
            '3-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3': {
                id: '3-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
                due: [1697172086399, 1697258486399],
                status: 'To Do',
                labels: ['Technology', 'Marketing'],
                comments: [
                    {
                        id: '7b3d0f80-c132-466d-9b85-e68050cda375',
                        name: 'Jayvion Simon',
                        createdAt: '2023-10-10T04:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg',
                        messageType: 'text',
                        message: 'The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.'
                    },
                    {
                        id: '5fc726a5-3e8d-400e-bc81-8bca99358663',
                        name: 'Lucian Obrien',
                        createdAt: '2023-10-09T03:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_7.jpg'
                    }
                ],
                assignee: [
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                        name: 'Jayvion Simon',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                        name: 'Lucian Obrien',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg'
                    }
                ],
                priority: 'medium',
                attachments: [],
                reporter: {
                    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
                    name: 'Angelique Morse',
                    avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_17.jpg'
                },
                name: 'Design User Interface Mockups',
                description:
                    'Rerum eius velit dolores. Explicabo ad nemo quibusdam. Voluptatem eum suscipit et ipsum et consequatur aperiam quia. Rerum nulla sequi recusandae illum velit quia quas. Et error laborum maiores cupiditate occaecati.'
            },
            '4-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4': {
                id: '4-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
                due: [1697258486399, 1697344886399],
                status: 'In Progress',
                labels: ['Technology', 'Marketing', 'Design'],
                comments: [
                    {
                        id: '7b3d0f80-c132-466d-9b85-e68050cda375',
                        name: 'Jayvion Simon',
                        createdAt: '2023-10-10T04:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg',
                        messageType: 'text',
                        message: 'The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.'
                    },
                    {
                        id: '5fc726a5-3e8d-400e-bc81-8bca99358663',
                        name: 'Lucian Obrien',
                        createdAt: '2023-10-09T03:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_7.jpg'
                    },
                    {
                        id: 'ff0f690f-f155-4426-bac6-15b9ead2397a',
                        name: 'Deja Brady',
                        createdAt: '2023-10-08T02:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_8.jpg'
                    }
                ],
                assignee: [
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                        name: 'Jayvion Simon',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                        name: 'Lucian Obrien',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
                        name: 'Deja Brady',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg'
                    }
                ],
                priority: 'hight',
                attachments: [],
                reporter: {
                    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
                    name: 'Angelique Morse',
                    avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_17.jpg'
                },
                name: 'Develop Backend API',
                description:
                    'Et non omnis qui. Qui sunt deserunt dolorem aut velit cumque adipisci aut enim. Nihil quis quisquam nesciunt dicta nobis ab aperiam dolorem repellat. Voluptates non blanditiis. Error et tenetur iste soluta cupiditate ratione perspiciatis et. Quibusdam aliquid nam sunt et quisquam non esse.'
            },
            '5-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5': {
                id: '5-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
                due: [1697344886399, 1697431286399],
                status: 'In Progress',
                labels: ['Technology', 'Marketing', 'Design', 'Photography'],
                comments: [
                    {
                        id: '7b3d0f80-c132-466d-9b85-e68050cda375',
                        name: 'Jayvion Simon',
                        createdAt: '2023-10-10T04:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg',
                        messageType: 'text',
                        message: 'The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.'
                    },
                    {
                        id: '5fc726a5-3e8d-400e-bc81-8bca99358663',
                        name: 'Lucian Obrien',
                        createdAt: '2023-10-09T03:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_7.jpg'
                    },
                    {
                        id: 'ff0f690f-f155-4426-bac6-15b9ead2397a',
                        name: 'Deja Brady',
                        createdAt: '2023-10-08T02:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_8.jpg'
                    },
                    {
                        id: 'e48b3c5d-218a-45a0-bdb0-9001c884b598',
                        name: 'Harrison Stein',
                        createdAt: '2023-10-07T01:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_4.jpg',
                        messageType: 'text',
                        message: 'The aroma of freshly brewed coffee filled the air, awakening my senses.'
                    }
                ],
                assignee: [
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                        name: 'Jayvion Simon',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                        name: 'Lucian Obrien',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
                        name: 'Deja Brady',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
                        name: 'Harrison Stein',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_4.jpg'
                    }
                ],
                priority: 'medium',
                attachments: [],
                reporter: {
                    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
                    name: 'Angelique Morse',
                    avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_17.jpg'
                },
                name: 'Implement Authentication System',
                description:
                    'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.'
            },
            '6-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6': {
                id: '6-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
                due: [1697431286399, 1697517686399],
                status: 'Done',
                labels: ['Technology', 'Marketing', 'Design', 'Photography', 'Art'],
                comments: [
                    {
                        id: '7b3d0f80-c132-466d-9b85-e68050cda375',
                        name: 'Jayvion Simon',
                        createdAt: '2023-10-10T04:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg',
                        messageType: 'text',
                        message: 'The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.'
                    },
                    {
                        id: '5fc726a5-3e8d-400e-bc81-8bca99358663',
                        name: 'Lucian Obrien',
                        createdAt: '2023-10-09T03:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_7.jpg'
                    },
                    {
                        id: 'ff0f690f-f155-4426-bac6-15b9ead2397a',
                        name: 'Deja Brady',
                        createdAt: '2023-10-08T02:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg',
                        messageType: 'image',
                        message: 'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_8.jpg'
                    },
                    {
                        id: 'e48b3c5d-218a-45a0-bdb0-9001c884b598',
                        name: 'Harrison Stein',
                        createdAt: '2023-10-07T01:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_4.jpg',
                        messageType: 'text',
                        message: 'The aroma of freshly brewed coffee filled the air, awakening my senses.'
                    },
                    {
                        id: '335a1afa-feac-446b-9e23-15522d9ca4a9',
                        name: 'Reece Chung',
                        createdAt: '2023-10-06T00:41:26.399Z',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_5.jpg',
                        messageType: 'text',
                        message: 'The children giggled with joy as they ran through the sprinklers on a hot summer day.'
                    }
                ],
                assignee: [
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
                        name: 'Jayvion Simon',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
                        name: 'Lucian Obrien',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
                        name: 'Deja Brady',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
                        name: 'Harrison Stein',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_4.jpg'
                    },
                    {
                        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
                        name: 'Reece Chung',
                        avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_5.jpg'
                    }
                ],
                priority: 'low',
                attachments: [
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_5.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_6.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_7.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_8.jpg',
                    'https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_9.jpg'
                ],
                reporter: {
                    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
                    name: 'Angelique Morse',
                    avatarUrl: 'https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_17.jpg'
                },
                name: 'Write Test Cases',
                description:
                    'Non rerum modi. Accusamus voluptatem odit nihil in. Quidem et iusto numquam veniam culpa aperiam odio aut enim. Quae vel dolores. Pariatur est culpa veritatis aut dolorem.'
            }
        },
        ordered: [
            '1-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
            '2-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
            '3-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
            '4-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4'
        ]
    }
}

function objFromArray(array, key = 'id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current
        return accumulator
    }, {})
}

const initialState = {
    isLoading: false,
    error: null,
    board: {
        cards: daa.board.tasks,
        columns: daa.board.columns,
        columnOrder: daa.board.ordered
    },
    tasks: Object.values(daa.board.tasks)
}

const slice = createSlice({
    name: 'kanban',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET BOARD
        getBoardSuccess(state, action) {
            state.isLoading = false
            const board = action.payload
            const cards = objFromArray(board.cards)
            const columns = objFromArray(board.columns)
            const { columnOrder } = board
            state.board = {
                cards,
                columns,
                columnOrder
            }
        },

        // CREATE NEW COLUMN
        createColumnSuccess(state, action) {
            const newColumn = action.payload
            state.isLoading = false
            state.board.columns = {
                ...state.board.columns,
                [newColumn.id]: newColumn
            }
            state.board.columnOrder.push(newColumn.id)
        },

        persistCard(state, action) {
            const columns = action.payload
            state.board.columns = columns
        },

        persistColumn(state, action) {
            state.board.columnOrder = action.payload
        },

        addTask(state, action) {
            const { card, columnId } = action.payload

            state.board.cards[card.id] = card
            state.board.columns[columnId].cardIds.push(card.id)
        },

        deleteTask(state, action) {
            const { cardId, columnId } = action.payload

            state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId)
            state.board.cards = omit(state.board.cards, [cardId])
        },

        // UPDATE COLUMN
        updateColumnSuccess(state, action) {
            const column = action.payload

            state.isLoading = false
            state.board.columns[column.id] = column
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            const { columnId } = action.payload
            const deletedColumn = state.board.columns[columnId]

            state.isLoading = false
            state.board.columns = omit(state.board.columns, [columnId])
            state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds])
            state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId)
        }
    }
})

// Reducer
export default slice.reducer

export const { actions } = slice

// ----------------------------------------------------------------------

export function getBoard() {
    return async () => {
        // dispatch(slice.actions.startLoading())
        try {
            // const response = await axios.get('https://api-prod-minimal-v510.vercel.app/api/kanban/board')
            // dispatch(slice.actions.getBoardSuccess(response.data.board))
            // const response = await axios.get('https://api-prod-minimal-v510.vercel.app/api/kanban/board')
            dispatch(slice.actions.getBoardSuccess(daa.board))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
    return async () => {
        // dispatch(slice.actions.startLoading())
        try {
            const response = await axios.post('https://api-prod-minimal-v510.vercel.app/api/kanban/columns/new', newColumn)
            dispatch(slice.actions.createColumnSuccess(response.data.column))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.post('https://api-prod-minimal-v510.vercel.app/api/kanban/columns/update', {
                columnId,
                updateColumn
            })
            dispatch(slice.actions.updateColumnSuccess(response.data.column))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.post('https://api-prod-minimal-v510.vercel.app/api/kanban/columns/delete', { columnId })
            dispatch(slice.actions.deleteColumnSuccess({ columnId }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
    return () => {
        dispatch(slice.actions.persistColumn(newColumnOrder))
    }
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
    return () => {
        dispatch(slice.actions.persistCard(columns))
    }
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
    return () => {
        dispatch(slice.actions.addTask({ card, columnId }))
    }
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
    return (dispatch) => {
        dispatch(slice.actions.deleteTask({ cardId, columnId }))
    }
}
