import {
    OPEN_SIDEBAR,
    SET_ACTIVE_TEAM_ID,
    SET_SIDEBAR_WIDTH,
    SET_PREBUILT_TOOLS,
    BB_INIT_LC_FLOW,
    SET_LC_FLOWS,
    SET_LC_FLOW,
    ADD_NEW_KB_DIALOG_OPEN,
    ALL_KB_DIALOG_OPEN,
    ADD_CONTENT_KB_DIALOG_OPEN,
    SHARE_LC_FLOW,
    CUSTOMIZE_LC_FLOW,
    EDIT_CONTENT_KB_DIALOG_OPEN,
    IS_CHATTING,
    SET_EDGE_ID,
    SET_PARENT,
    SET_KBS,
    SET_KB,
    SET_EXPANDED,
    SET_ACTIVE_CONDITION,
    SET_DELETING,
    SET_UPDTATING,
    ANY_UNSAVED_CHANGES,
    SET_ACTIVE_FLOW_ID
} from '../lcFlowActions'

export const initialState = {
    lcflow: null,
    editLcFlow: null,
    sidebarWidth: '400px',
    lcflows: [],
    knowledgeBases: [],
    allKbDialogOpen: false,
    addNewKbDialogOpen: false,
    editKbDialogOpen: false,
    shareLcFlowDialogOpen: false,
    customizeLcFlowDialogOpen: false,
    sidebarOpen: false,
    sidebarData: null,
    isChatting: false,
    edgeId: null,
    lastAddedStep: null,
    sidebarType: null,
    parent: null,
    updateLcFlowTime: null,
    activeTeamId: 0,
    lcflowVariables: {},
    expanded: [],
    updateLcFlowLoading: null,
    activeCondition: null,
    deleting: null,
    updating: null,
    activeFlowId: null,
    anyUnsavedChanges: false,
    runMode: 'Chat',
    prebuiltTools: [],
    prebuiltAgents: []
}

const basicLcFlowReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR_WIDTH:
            return {
                ...state,
                sidebarWidth: action.payload
            }
        case SET_PREBUILT_TOOLS:
            return {
                ...state,
                prebuiltTools: action.payload
            }
        case 'SET_PREBUILT_AGENTS':
            return {
                ...state,
                prebuiltAgents: action.payload ?? []
            }

        case SET_ACTIVE_TEAM_ID:
            return {
                ...state,
                activeTeamId: action.payload
            }
        case 'SET_RUN_MODE':
            return {
                ...state,
                runMode: action.payload
            }
        case SET_ACTIVE_FLOW_ID:
            return {
                ...state,
                activeFlowId: action.payload
            }
        case ANY_UNSAVED_CHANGES:
            return {
                ...state,
                anyUnsavedChanges: action.payload
            }
        case SET_DELETING:
            return {
                ...state,
                deleting: action.payload
            }
        case SET_UPDTATING:
            return {
                ...state,
                updating: action.payload
            }
        case SET_ACTIVE_CONDITION:
            return {
                ...state,
                activeCondition: action.id
            }
        case 'UPDATE_LC_FLOW_LOADING':
            return {
                ...state,
                updateLcFlowLoading: action.payload
            }
        case SET_EXPANDED:
            return {
                ...state,
                expanded: action.payload
            }
        case 'SET_LC_FLOW_VARIABLES':
            return {
                ...state,
                lcflowVariables: { ...state.lcflowVariables, ...action.payload }
            }
        case SET_KBS:
            return {
                ...state,
                knowledgeBases: action.payload
            }
        case SET_KB:
            return {
                ...state,
                knowledgeBases: state.knowledgeBases.map((kb) => {
                    if (kb.id === action.payload.id) {
                        return action.payload
                    }
                    return kb
                })
            }
        case 'REMOVE_OLD_VALUES':
            return {
                ...state,
                activeTeamId: 0,
                lcflow: null,
                editLcFlow: null,
                allKbDialogOpen: false,
                addNewKbDialogOpen: false,
                editKbDialogOpen: false,
                shareLcFlowDialogOpen: false,
                customizeLcFlowDialogOpen: false,
                sidebarOpen: false,
                sidebarData: null,
                isChatting: false,
                edgeId: null,
                lastAddedStep: null,
                sidebarType: null,
                parent: null,
                updateLcFlowTime: null
            }

        case 'UPDATE_LC_FLOW':
            return {
                ...state,
                updateLcFlowTime: action.payload
            }
        case SET_PARENT:
            return {
                ...state,
                parent: action.payload
            }
        case SET_EDGE_ID:
            return {
                ...state,
                edgeId: action.payload
            }
        case 'SET_LC_FLOW_SIDEBAR_TYPE':
            return {
                ...state,
                sidebarType: action.payload
            }
        case IS_CHATTING:
            return {
                ...state,
                isChatting: action.payload,
                sidebarData: null,
                sidebarOpen: false,
                sidebarType: null,
                parent: null
            }
        case EDIT_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                editKbDialogOpen: !!action.payload,
                editLcFlow: action.payload ?? null
            }
        case OPEN_SIDEBAR:
            return {
                ...state,
                sidebarOpen: !!action.payload,
                sidebarData: action.payload ?? null
            }
        case ALL_KB_DIALOG_OPEN:
            return {
                ...state,
                allKbDialogOpen: action.payload
            }
        case SET_LC_FLOW:
            return {
                ...state,
                lcflow: action.lcflow
            }
        case SHARE_LC_FLOW:
            return {
                ...state,
                shareLcFlowDialogOpen: action.payload
            }
        case CUSTOMIZE_LC_FLOW:
            return {
                ...state,
                customizeLcFlowDialogOpen: action.payload
            }
        case ADD_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: !!action.payload,
                lcflow: action.payload ?? null
            }
        case ADD_NEW_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: action.payload,
                editLcFlow: null,
                editKbDialogOpen: false
            }
        case SET_LC_FLOWS:
            return {
                ...state,
                lcflows: action.lcflows
            }
        case BB_INIT_LC_FLOW:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default basicLcFlowReducer
