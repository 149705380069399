/* eslint-disable no-case-declarations */
const sortCell = (cells, newCells) => {
    newCells.forEach((cell) => {
        const findCell = cells.find((c) => c._id === cell._id)
        if (!findCell) {
            cells = [...cells, cell]
        }
    })
    // sor cell by cell.index
    cells.sort((a, b) => a.index - b.index)
    return cells
}
const handleeAiCellUpdate = (cells, newCells) => {
    const c = cells.map((cell) => {
        const findCell = newCells.find((c) => c.uiId === cell.uiId)
        if (findCell) {
            return findCell
        }
        return cell
    })
    return [...new Set(c)]
}
const handleDeleteAiCell = (cells, toDeleteCells) => {
    const toDeleteUiids = toDeleteCells.map((cell) => cell._id)
    const c = cells.filter((cell) => !toDeleteUiids.includes(cell._id))
    return [...new Set(c)]
}

const initialState = {
    width: 240,
    height: 40,
    table: null,
    headings: [],
    rows: [],
    activeCellId: null,
    activeRow: null,
    panelTitle: null,
    selectedRows: [],
    expandCell: null,
    expandRow: null,
    clickOutsideTime: null,
    popover: null,
    editCellTitle: null,
    focusCell: null,
    openChat: false,
    messages: [
        {
            message: 'Hello, how can I help you?',
            sender: 'bot'
        }
    ]
}
const datagridReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_MESSAGES_DG':
            return {
                ...state,
                messages: payload
            }
        case 'DELETE_CELLS_DG':
            return {
                ...state,
                headings: handleDeleteAiCell(state.headings, payload)
            }
        case 'ADD_MESSAGE_DG':
            return {
                ...state,
                messages: [...state.messages, payload]
            }
        case 'CLOSE_CHAT_DG':
            return {
                ...state,
                openChat: false
            }
        case 'START_CHAT_DG':
            return {
                ...state,
                openChat: true
            }
        case 'SET_FOCUS_CELL_DG':
            return {
                ...state,
                focusCell: payload
            }
        case 'SORT_ROWS':
            return {
                ...state,
                rows: [...state.rows].sort((a, b) => {
                    if (payload.datatype === 'number') {
                        const aValue = a[payload.headerID] ?? 0
                        const bValue = b[payload.headerID] ?? 0
                        if (payload.order === 'ASC') {
                            return aValue > bValue ? 1 : -1
                        }
                        return aValue < bValue ? 1 : -1
                    } else if (payload.datatype === 'date') {
                        const aValue = new Date(a[payload.headerID]) ?? 0
                        const bValue = new Date(b[payload.headerID]) ?? 0
                        if (payload.order === 'ASC') {
                            return aValue > bValue ? 1 : -1
                        }
                        return aValue < bValue ? 1 : -1
                    } else {
                        let aValue = a[payload.headerID] ?? ''
                        let bValue = b[payload.headerID] ?? ''
                        if (typeof aValue !== 'string') {
                            aValue = JSON.stringify(aValue)
                        }
                        if (typeof bValue !== 'string') {
                            bValue = JSON.stringify(bValue)
                        }
                        if (payload.order === 'ASC') {
                            return aValue.localeCompare(bValue)
                        }
                        return bValue.localeCompare(aValue)
                    }
                })
            }
        case 'START_RENAME_HEADING_DG':
            return {
                ...state,
                editCellTitle: payload
            }

        case 'CLICK_OUTSIDE':
            return {
                ...state,
                clickOutsideTime: payload
            }

        case 'SET_HEIGHT_DG':
            return {
                ...state,
                height: payload
            }
        case 'SET_EXPAND_ROW':
            return {
                ...state,
                expandRow: payload
            }
        case 'EXPAND_CELL':
            return {
                ...state,
                expandCell: payload
            }
        case 'ADD_ROWS_DG':
            return {
                ...state,
                rows: [...state.rows, ...payload]
            }
        case 'RUN_ON_SELECTED_ROW_START':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if ((payload.selectedRows ?? []).includes(row._id)) {
                        return {
                            ...row,
                            [payload.cellId]: 'Queued...'
                        }
                    }
                    return row
                })
            }
        case 'SET_SELECTED_ROWS':
            return {
                ...state,
                selectedRows: payload
            }
        case 'ADD_AI_GENERATED_CELLS':
            return {
                ...state,
                headings: [...new Set(sortCell(state.headings, payload))]
            }
        case 'SET_SELECTED_ROW_DG':
            return {
                ...state,
                selectedRows: [
                    ...new Set(
                        payload.isChecked
                            ? [...state.selectedRows, payload.rowId]
                            : state.selectedRows.filter((rowId) => rowId !== payload.rowId)
                    )
                ]
            }
        case 'HIDE_HEADING_DG':
            return {
                ...state,
                headings: state.headings.map((heading) => {
                    if (heading.uiId === payload) {
                        return { ...heading, hidden: !heading.hidden }
                    }
                    return heading
                })
            }
        case 'REORDER_HEADING_DG':
            return {
                ...state,
                headings: payload
            }
        case 'SET_DEFAULT_VALUES_ROW':
            return {
                ...state,
                rows: [
                    ...state.rows.map((row) => {
                        return {
                            ...row,
                            [payload.key]: row[payload.key] ?? payload.value
                        }
                    })
                ]
            }
        case 'SET_TABLE_DG':
            return {
                ...state,
                table: payload
            }
        case 'SET_DRAWER_OPEN':
            return {
                ...state,
                isDrawerOpen: payload
            }
        case 'EDIT_HEADING_DG':
            return {
                ...state,
                // isDrawerOpen: !!payload,
                activeHeadingId: payload
                // panelTitle: 'Edit Column'
            }
        case 'SET_HEADINGS_DG':
            return {
                ...state,
                headings: payload
            }
        case 'ADD_HEADING_DG':
            return {
                ...state,
                headings: [...state.headings, payload]
            }
        case 'DELETE_HEADING_DG':
            return {
                ...state,
                headings: state.headings
                    .filter((heading) => heading.uiId !== payload.uiId)
                    .map((heading) => {
                        if (heading.index > payload.index) {
                            return { ...heading, index: heading.index - 1 }
                        }
                        return heading
                    })
            }
        case 'UPDATE_HEADING_DG':
            let newHeadings = state.headings
            const findHeading = newHeadings.find((heading) => heading.uiId === payload.uiId)
            if (findHeading) {
                newHeadings = state.headings.map((heading) => {
                    if (heading.uiId === payload.uiId) {
                        return payload
                    }
                    return heading
                })
            } else {
                newHeadings = [...state.headings, payload]
            }
            return {
                ...state,
                headings: [...new Set(newHeadings)]
            }

        case 'UPDATE_CELLS_DG':
            return {
                ...state,
                headings: handleeAiCellUpdate(state.headings, payload)
            }

        case 'SET_SELECTED_CELL_DG':
            return {
                ...state,
                activeCellId: payload,
                popover: payload ? state.popover : null
            }
        case 'SET_ANCHOR_EL_DG':
            return {
                ...state,
                popover: payload
            }
        case 'UPDATE_CELL_DATA_DG':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if (row.id === payload.rowId) {
                        return {
                            ...row,
                            [payload.cellId]: payload.value
                        }
                    }
                    return row
                })
            }
        case 'SET_ROWS_DG':
            return {
                ...state,
                rows: payload
            }
        case 'ADD_ROW_DG':
            return {
                ...state,
                rows: [...state.rows, payload]
            }
        case 'DELETE_ROW_DG':
            return {
                ...state,
                rows: state.rows.filter((row) => row._id !== payload.rowId)
            }
        case 'DELETE_ROWS_DG':
            return {
                ...state,
                rows: state.rows.filter((row) => !payload.includes(row._id)),
                selectedRows: []
            }
        case 'UPDATE_ROW_DG':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if (row._id === payload._id) {
                        return payload
                    }
                    return row
                })
            }
        case 'UPDATE_ROWS':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if (payload.includes(row._id)) {
                        return {
                            ...row,
                            ...payload
                        }
                    }
                    return row
                })
            }
        case 'UPDATE_COLUMN':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    return {
                        ...row,
                        [payload.columnId]: payload.column
                    }
                })
            }
    }
    return state
}
export default datagridReducer
