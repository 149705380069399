/* eslint-disable react/prop-types */
import { Box } from '@mui/material'
const Logo = ({ smallIcon }) => {
    return (
        <Box justifyContent={{ xs: 'center', md: 'space-between' }} sx={{ textAlign: { xs: 'center', md: 'left' }, display: 'flex' }}>
            {smallIcon ? (
                <img src='/assets/small-logo.png' alt='Meerkats AI' />
            ) : (
                <img
                    style={{ objectFit: 'contain', height: 'auto', width: 150 }}
                    // src={customization.isDarkMode ? logoDark : logo}
                    src='/logo/logo.svg'
                    alt='Meerkats AI'
                />
            )}
        </Box>
    )
}

export default Logo
