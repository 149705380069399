import axios from '../../../utils/axios'
import * as ActionTypes from './Types'
import { dispatch } from '../../../store'

export const setToast = (payload) => ({
    type: ActionTypes.SET_TOAST,
    payload
})

export const setLoading = (payload) => ({
    type: ActionTypes.LOADING,
    payload
})
export const setBackdrop = (payload) => ({
    type: ActionTypes.BACKDROP,
    payload
})
export const logout = () => ({
    type: ActionTypes.LOGOUT
})
export const setSignUpError = (payload) => ({
    type: ActionTypes.SIGN_UP_FAILED,
    payload
})

export const setSignUpSuccess = (payload) => ({
    type: ActionTypes.SIGN_UP_SUCCESS
})

export const setLoginSuccess = (payload) => ({
    type: ActionTypes.LOGIN_SUCCESS,
    payload
})
export const setMailSent = (payload) => ({
    type: ActionTypes.FORGET_EMAIL_SENT,
    payload
})
export const recoverPasswordSuccess = (payload) => ({
    type: ActionTypes.RECOVER_PASSWORD,
    payload
})

export const setAssessment = (payload) => ({
    type: ActionTypes.SET_ASSESSMENT,
    payload
})

export const setVerifyToken = (payload) => ({
    type: ActionTypes.SET_TOKEN_VERIFICATION_STATUS,
    payload
})

export const login = (payload) => {
    return async () => {
        dispatch(setBackdrop(true))
        const data = await axios.post(`user/login`, payload)
        dispatch(setBackdrop(false))
        if (data && data.message && data.message === 'Email & Password is not valid.') {
            dispatch(setToast({ severity: 'error', message: data.message, open: true }))
        } else if (data && data.code === 202) {
            dispatch(setToast({ severity: 'error', message: data.message, open: true }))
        } else if (data && !data.code) {
            dispatch(setLoginSuccess(data))
            dispatch(setToast({ severity: 'success', message: 'Login Success', open: true }))
        } else {
            dispatch(setToast({ severity: 'error', message: 'Something went wrong', open: true }))
        }
    }
}
export const forgetPassword = (payload) => {
    return async () => {
        dispatch(setLoading(true))
        try {
            const { data, status } = await axios.post(`auth/forgot-password`, payload)
            dispatch(setMailSent(true))
            dispatch(setLoading(false))
            dispatch(setToast({ severity: 'success', message: 'Please check your email', open: true }))
        } catch (error) {
            dispatch(setLoading(false))
            dispatch(setToast({ severity: 'error', message: error.message ? error.message : 'Something went wrong', open: true }))
        }
    }
}
export const recoverPassword = (password, token, cb) => {
    return async () => {
        dispatch(setLoading(true))
        try {
            const { data, status } = await axios.post(`auth/reset-password?token=${token}`, { password })
            dispatch(setLoading(false))
            dispatch(setToast({ severity: 'success', message: 'Password reset completed , Please login', open: true }))
            dispatch(setMailSent(null))
            cb()
        } catch (error) {
            dispatch(setLoading(false))
            dispatch(setToast({ severity: 'error', message: error.message ? error.message : 'Something went wrong', open: true }))
        }
    }
}
export const getAssessment = (id) => {
    return async () => {
        dispatch(setLoading(true))
        try {
            const { data, status } = await axios.get(`auth/invitation/${id}`)
            dispatch(setAssessment(data))
        } catch (error) {
            dispatch(setLoading(false))
            dispatch(setToast({ severity: 'error', message: error.message ? error.message : 'Something went wrong', open: true }))
        }
    }
}
export const resendOtp = (email) => {
    return async () => {
        dispatch(setLoading(true))
        try {
            const { data, status } = await axios.post(`auth/resendOtp`, { email })
            console.log(data)
            if (data.msg) {
                dispatch(setToast({ severity: 'success', message: data.msg, open: true }))
            } else {
                dispatch(setToast({ severity: 'warning', message: data.msg, open: true }))
            }
        } catch (error) {
            dispatch(setLoading(false))
            dispatch(setToast({ severity: 'error', message: error.message ? error.message : 'Something went wrong', open: true }))
        }
    }
}

export const register = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
    }
}
