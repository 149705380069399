import { lazy, useEffect } from 'react'

// project imports
import DashboardLayout from '../tailwindui/DashboardLayout'
// import MinimalLayout from '../layout/dashboard/Minimal'
import Loadable from 'ui-component/loading/Loadable'
import AuthGuard from 'guards/AuthGuard'
import OAuthCallback from 'views/agents/oauthCallback'
import GuestGuard from 'guards/GuestGuard'
import Main from 'layout/main'
const Login = Loadable(lazy(() => import('../views/auth/Login')))
// import PlaygroundTest from 'tests/PlaygroundTest'
// const Home = Loadable(lazy(() => import('views/Home')))
const PromptChainsLanding = Loadable(lazy(() => import('views/usertables/PromptChainsLanding')))
const NotFound = Loadable(lazy(() => import('views/Page404')))

const SharedWorkbook = Loadable(lazy(() => import('views/workbook/SharedWorkbook')))
const Playground = Loadable(lazy(() => import('views/workbook/Playground')))
// const PlaygroundPublic = Loadable(lazy(() => import('views/workbook/PlaygroundPublic')))
const Crawl = Loadable(lazy(() => import('views/crawl')))
const Upgrade = Loadable(lazy(() => import('tailwindui/pages/Upgrade')))
const AiPages = Loadable(lazy(() => import('tailwindui/pages/ai-pages')))
const Activity = Loadable(lazy(() => import('views/Activity')))
const Tables = Loadable(lazy(() => import('tailwindui/pages/tables')))
const Home = Loadable(lazy(() => import('tailwindui/pages/home')))
const Logout = () => {
    useEffect(() => {
        localStorage.clear()
        window.location.href = '/'
    }, [])
    return <div>Logout...</div>
}
export const getRoutes = (isAdmin) => {
    if (isAdmin) {
        const TokenUsage = Loadable(lazy(() => import('views/Users/TokenUsage')))
        const UserAccount = Loadable(lazy(() => import('views/dashboard/UserAccount')))
        const Users = Loadable(lazy(() => import('views/Users')))
        const User = Loadable(lazy(() => import('views/Users/User')))
        const Connections = Loadable(lazy(() => import('views/agents/connections')))
        const DummyAgents = Loadable(lazy(() => import('views/agents/DummyAgents')))
        const Usertables = Loadable(lazy(() => import('views/usertables')))
        const Usertable = Loadable(lazy(() => import('views/usertable')))
        const Workbook = Loadable(lazy(() => import('views/workbook')))
        const UsertableCreate = Loadable(lazy(() => import('views/usertable/NewUsertable')))
        const Document = Loadable(lazy(() => import('views/documents')))
        const DocumentDetail = Loadable(lazy(() => import('views/documents/documentDetail')))
        const Blogs = Loadable(lazy(() => import('views/blogs')))
        const BlogDetail = Loadable(lazy(() => import('views/blogs/blogDetail')))
        const Subdomains = Loadable(lazy(() => import('views/subdomains')))
        const SubdomainDetail = Loadable(lazy(() => import('views/subdomains/SubdomainDetail')))
        const UserReport = Loadable(lazy(() => import('views/Users/UserReport')))
        const MainRoutes = {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <Home />
                },
                {
                    path: '/dashboard/start-with-ai',
                    element: <PromptChainsLanding />
                },
                {
                    path: '/dashboard/tables',
                    element: <Tables />
                },
                {
                    path: '/dashboard/ai-pages/:tableId/:rowId',
                    element: <AiPages />
                },
                {
                    path: '/dashboard/crawl/:id',
                    element: <Crawl />
                },
                {
                    path: '/dashboard/crawl',
                    element: <Crawl />
                },
                {
                    path: '/dashboard/upgrade',
                    element: <Upgrade />
                },
                {
                    path: '/dashboard/prompt-chains',
                    element: <Usertables Onboarding />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/playground/:id/:rowId',
                    element: <Playground />
                },
                {
                    path: '/dashboard/playground/:id',
                    element: <Playground />
                },
                {
                    path: '/dashboard/documents/:id',
                    element: <DocumentDetail />
                },
                {
                    path: '/dashboard/activity/:type',
                    element: <Activity />
                },
                {
                    path: '/dashboard/users/:id/documents',
                    element: <UserReport entity='documents' />
                },
                {
                    path: '/dashboard/users/:id/runs',
                    element: <UserReport entity='runs' />
                },
                {
                    path: '/dashboard/users/:id/prompt-chains',
                    element: <UserReport entity='promt-chains' />
                },
                {
                    path: '/dashboard/documents',
                    element: <Document />
                },
                {
                    path: '/dashboard/usertable/:id',
                    element: <Usertable />
                },
                {
                    path: '/dashboard/prompt-chains/:id/:rowId',
                    element: <Workbook />
                },
                {
                    path: '/dashboard/prompt-chains/:id',
                    element: <Workbook />
                },
                {
                    path: '/dashboard/usertables',
                    element: <Usertables />
                },
                {
                    path: '/dashboard/usertable/new',
                    element: <UsertableCreate />
                },
                {
                    path: '/dashboard/agents',
                    element: <DummyAgents />
                },
                // {
                //     path: '/dashboard/agents/new',
                //     element: <NewAgent />
                // },
                // {
                //     path: '/dashboard/agents/:id',
                //     element: <NewAgent isEdit />
                //     // element: <EditAgent />
                // },
                // {
                //     path: '/dashboard/tools/:id/:agentId',
                //     element: <LcTool isEdit />
                // },
                // {
                //     path: '/dashboard/tools/:id',
                //     element: <LcTool isEdit />
                // },
                // {
                //     path: '/dashboard/tools',
                //     element: <LcTools />
                // },
                {
                    path: '/dashboard/connections',
                    element: <Connections />
                },
                {
                    path: '/dashboard/usage',
                    element: <TokenUsage />
                },
                {
                    path: '/dashboard/users/:id',
                    element: <User />
                },
                {
                    path: '/dashboard/users',
                    element: <Users />
                },
                // {
                //     path: '/dashboard/users/:id/tables',
                //     element: <UserTable />
                // },
                // {
                //     path: '/dashboard/knowledge-base',
                //     element: <Knowledgebase />
                // },
                {
                    path: '/dashboard/user/account',
                    element: <UserAccount />
                },
                {
                    path: '/dashboard/blogs/new',
                    element: <BlogDetail isNew />
                },
                {
                    path: '/dashboard/blogs/:id',
                    element: <BlogDetail />
                },
                {
                    path: '/dashboard/blogs',
                    element: <Blogs />
                },
                {
                    path: '/dashboard/apps/:id',
                    element: <Playground isApp />
                }
            ]
        }
        return MainRoutes
    } else {
        const TokenUsage = Loadable(lazy(() => import('views/bots/TokenUsage')))
        const UserAccount = Loadable(lazy(() => import('views/dashboard/UserAccount')))
        const Connections = Loadable(lazy(() => import('views/agents/connections')))
        const Usertable = Loadable(lazy(() => import('views/usertable')))
        const Workbook = Loadable(lazy(() => import('views/workbook')))
        const Document = Loadable(lazy(() => import('views/documents')))
        const DocumentDetail = Loadable(lazy(() => import('views/documents/documentDetail')))
        const Usertables = Loadable(lazy(() => import('views/usertables')))
        const MainRoutes = {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <PromptChainsLanding />
                },
                {
                    path: '/dashboard/tables',
                    element: <Tables />
                },
                {
                    path: '/dashboard/ai-pages/:tableId/:rowId',
                    element: <AiPages />
                },
                {
                    path: '/dashboard/activity/:type',
                    element: <Activity />
                },
                {
                    path: '/dashboard/upgrade',
                    element: <Upgrade />
                },
                {
                    path: '/dashboard/prompt-chains',
                    element: <Usertables Onboarding />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/playground/:id/:rowId',
                    element: <Playground />
                },
                {
                    path: '/dashboard/playground/:id',
                    element: <Playground />
                },
                {
                    path: '/dashboard/apps/:id',
                    element: <Playground isApp />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/playground/:id/:rowId',
                    element: <Playground />
                },
                {
                    path: '/dashboard/playground/:id',
                    element: <Playground />
                },
                {
                    path: '/dashboard/documents/:id',
                    element: <DocumentDetail />
                },
                {
                    path: '/dashboard/documents',
                    element: <Document />
                },
                {
                    path: '/dashboard/usertable/:id',
                    element: <Usertable />
                },
                {
                    path: '/dashboard/prompt-chains/:id/:rowId',
                    element: <Workbook />
                },
                {
                    path: '/dashboard/prompt-chains/:id',
                    element: <Workbook />
                },
                {
                    path: '/dashboard/connections',
                    element: <Connections />
                },
                {
                    path: '/dashboard/usage',
                    element: <TokenUsage />
                },
                {
                    path: '/dashboard/user/account',
                    element: <UserAccount />
                }
            ]
        }
        return MainRoutes
    }
}
export const PublicRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <Main />
        </GuestGuard>
    ),
    children: [
        {
            path: '/auth/logout',
            element: <Logout />
        },
        {
            path: '/',
            element: <Login />
        },
        // {
        //     path: '/',
        //     element: <Home isCrux />
        // },
        // {
        //     path: '/crux-ai-researcher',
        //     element: <Home isCrux />
        // },
        // {
        //     path: '/content-crafter',
        //     element: <Home />
        // },
        // {
        //     path: '/playground/:id/',
        //     element: <PlaygroundPublic isApp isPublic />
        // },
        // {
        //     path: '/pricing',
        //     element: <Upgrade />
        // },
        // {
        //     path: '/redirect',
        //     element: <OAuthCallback />
        // },

        // {
        //     path: '/shared-workbook/:id/:rowId',
        //     element: <SharedWorkbook />
        // },
        {
            path: '/redirect',
            element: <OAuthCallback />
        }
    ]
}
export const NotFoundRoutes = {
    path: '*',
    element: <NotFound />
}
