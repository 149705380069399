export const initialState = {
    lcTools: [],
    loading: false,
    mtTools: []
}

const lcToolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_LC_TOOL':
            return {
                ...state,
                lcTools: [...state.lcTools, action.payload]
            }
        case 'SET_LC_TOOLS':
            return {
                ...state,
                lcTools: action.payload ?? []
            }
        case 'SET_MT_TOOLS':
            return {
                ...state,
                mtTools: action.payload ?? []
            }
        case 'SET_LC_TOOLS_LOADING':
            return {
                ...state,
                loading: action.payload
            }

        default:
            return state
    }
}

export default lcToolsReducer
