import {
    BB_INIT_BOT,
    SET_BOTS,
    SET_BOT,
    ADD_NEW_KB_DIALOG_OPEN,
    ALL_KB_DIALOG_OPEN,
    ADD_CONTENT_KB_DIALOG_OPEN,
    SHARE_BOT,
    CUSTOMIZE_BOT,
    EDIT_CONTENT_KB_DIALOG_OPEN,
    OPEN_NODE_SIDEBAR,
    IS_CHATTING,
    SET_BOT_SIDEBAR_TYPE,
    SET_EDGE_ID,
    SET_PARENT,
    UPDATE_BOT,
    SET_KBS,
    SET_KB,
    SET_ACTIVE_INTENT,
    SET_BOT_VARIABLES,
    SET_EXPANDED,
    UPDATE_BOT_LOADING,
    SET_ACTIVE_CONDITION,
    SET_DELETING,
    SET_UPDTATING,
    ANY_UNSAVED_CHANGES,
    SET_ACTIVE_FLOW_ID,
    OPEN_SETTINGS
} from '../botActions'

export const initialState = {
    bot: null,
    editBot: null,
    bots: [],
    knowledgeBases: [],
    allKbDialogOpen: false,
    addNewKbDialogOpen: false,
    editKbDialogOpen: false,
    shareBotDialogOpen: false,
    customizeBotDialogOpen: false,
    sidebarOpen: false,
    sidebarData: null,
    isChatting: false,
    edgeId: null,
    lastAddedStep: null,
    sidebarType: null,
    parent: null,
    updateBotTime: null,
    activeIntent: 0,
    botVariables: {},
    expanded: [],
    updateBotLoading: null,
    activeCondition: null,
    deleting: null,
    updating: null,
    activeFlowId: null,
    anyUnsavedChanges: false,
    runMode: 'Chat'
}

const basicBotReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_RUN_MODE':
            return {
                ...state,
                runMode: action.payload
            }
        case SET_ACTIVE_FLOW_ID:
            return {
                ...state,
                activeFlowId: action.payload
            }
        case ANY_UNSAVED_CHANGES:
            return {
                ...state,
                anyUnsavedChanges: action.payload
            }
        case SET_DELETING:
            return {
                ...state,
                deleting: action.payload
            }
        case SET_UPDTATING:
            return {
                ...state,
                updating: action.payload
            }
        case SET_ACTIVE_CONDITION:
            return {
                ...state,
                activeCondition: action.id
            }
        case UPDATE_BOT_LOADING:
            return {
                ...state,
                updateBotLoading: action.payload
            }
        case SET_EXPANDED:
            return {
                ...state,
                expanded: action.payload
            }
        case SET_BOT_VARIABLES:
            return {
                ...state,
                botVariables: { ...state.botVariables, ...action.payload }
            }
        case SET_ACTIVE_INTENT:
            return {
                ...state,
                activeIntent: action.payload
            }
        case SET_KBS:
            return {
                ...state,
                knowledgeBases: action.payload
            }
        case SET_KB:
            return {
                ...state,
                knowledgeBases: state.knowledgeBases.map((kb) => {
                    if (kb.id === action.payload.id) {
                        return action.payload
                    }
                    return kb
                })
            }
        case 'REMOVE_OLD_VALUES':
            return {
                ...state,
                activeIntent: 0,
                bot: null,
                editBot: null,
                allKbDialogOpen: false,
                addNewKbDialogOpen: false,
                editKbDialogOpen: false,
                shareBotDialogOpen: false,
                customizeBotDialogOpen: false,
                sidebarOpen: false,
                sidebarData: null,
                isChatting: false,
                edgeId: null,
                lastAddedStep: null,
                sidebarType: null,
                parent: null,
                updateBotTime: null
            }

        case UPDATE_BOT:
            return {
                ...state,
                updateBotTime: action.payload
            }
        case SET_PARENT:
            return {
                ...state,
                parent: action.payload
            }
        case SET_EDGE_ID:
            return {
                ...state,
                edgeId: action.payload
            }
        case SET_BOT_SIDEBAR_TYPE:
            return {
                ...state,
                sidebarType: action.payload
            }
        case IS_CHATTING:
            return {
                ...state,
                isChatting: action.payload,
                sidebarData: null,
                sidebarOpen: false,
                sidebarType: null,
                parent: null
            }
        case EDIT_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                editKbDialogOpen: !!action.payload,
                editBot: action.payload ?? null
            }
        case OPEN_NODE_SIDEBAR:
            return {
                ...state,
                sidebarOpen: !!action.payload,
                sidebarData: action.payload ?? null,
                isChatting: false
            }
        case ALL_KB_DIALOG_OPEN:
            return {
                ...state,
                allKbDialogOpen: action.payload
            }
        case SET_BOT:
            return {
                ...state,
                bot: action.bot
            }
        case SHARE_BOT:
            return {
                ...state,
                shareBotDialogOpen: action.payload
            }
        case CUSTOMIZE_BOT:
            return {
                ...state,
                customizeBotDialogOpen: action.payload
            }
        case ADD_CONTENT_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: !!action.payload,
                bot: action.payload ?? null
            }
        case ADD_NEW_KB_DIALOG_OPEN:
            return {
                ...state,
                addNewKbDialogOpen: action.payload,
                editBot: null,
                editKbDialogOpen: false
            }
        case SET_BOTS:
            return {
                ...state,
                bots: action.bots
            }
        case BB_INIT_BOT:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default basicBotReducer
