/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import PropTypes from 'prop-types'
import { forwardRef, useRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
// @mui
import { Box, Link, ListItemText, Typography, Tooltip, Stack } from '@mui/material'
// hooks
import RoleBasedGuard from '../../../guards/RoleBasedGuard'
//
import Iconify from '../../Iconify'
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style'
import { isExternalLink } from '..'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Menu from '@mui/material/Menu'
import tableApi from '../../../api/usertable'
export default function AccountMenu({ anchorEl, open, handleClose, items, active, onOpen, isCollapse }) {
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: -4,
                        ml: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 25,
                            left: -5,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                {items.map((item, index) => (
                    <NavItemRoot isCollapse={isCollapse} item={item} open={open} onOpen={onOpen} active={active} key={index} />
                ))}
            </Menu>
        </>
    )
}
const ListItem = forwardRef((props, ref) => (
    <RoleBasedGuard roles={props.roles}>
        <ListItemStyle {...props} ref={ref}>
            {props.children}
        </ListItemStyle>
    </RoleBasedGuard>
))

ListItem.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.arrayOf(PropTypes.string)
}
export const AButton = (props) => {
    const onClick = async () => {
        try {
            const result = await tableApi.createNewWb({})
            if (result && result.data) {
                window.location.href = `/dashboard/prompt-chains/${result.data.tableId}/${result.data._id}`
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Box sx={{ py: '10px', width: '100%' }}>
            <RoleBasedGuard roles={props.roles}>
                <ListItemStyle {...props} onClick={onClick} special>
                    <AddCircleIcon sx={{ mr: 1 }} /> Start a Chain
                </ListItemStyle>
            </RoleBasedGuard>
        </Box>
    )
}
NavItemRoot.propTypes = {
    active: PropTypes.bool,
    open: PropTypes.bool,
    isCollapse: PropTypes.bool,
    onOpen: PropTypes.func,
    item: PropTypes.shape({
        children: PropTypes.array,
        icon: PropTypes.any,
        info: PropTypes.any,
        path: PropTypes.string,
        title: PropTypes.string,
        disabled: PropTypes.bool,
        caption: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string)
    })
}

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
    const ref = useRef(null)
    const { title, path, icon, info, children, disabled, caption, roles } = item
    const hasChildren = children ? children.length > 0 : false
    const handleClick = (event) => {
        event.preventDefault()
        if (!onOpen) return
        onOpen()
    }
    const handleClose = () => {}
    const onMouseEnter = () => {
        if (!onOpen) return
        onOpen()
    }
    const renderContent = (
        <>
            {/* {icon ? <img src={icon} alt={title} width={20} height={20} style={{ marginRight: '2px' }} /> : null} */}
            {/* <Box
                sx={{
                    width: '5px',
                    height: '16px',
                    backgroundColor: active ? '#2B0771' : 'transparent',
                    borderRadius: '0px',
                    mr: 0.5
                }}
            /> */}
            {isCollapse ? (
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={0} ref={ref} onMouseEnter={onMouseEnter}>
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={0}>
                        {icon ? icon : null}
                        <Typography
                            noWrap
                            variant='caption'
                            component='div'
                            sx={{ textTransform: 'initial', color: 'text.secondary', px: 0.3 }}
                        >
                            {title}
                        </Typography>
                    </Stack>

                    {hasChildren ? (
                        <>
                            <ArrowIcon
                                open={open}
                                onClick={handleClick}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup='true'
                                aria-expanded={open ? 'true' : undefined}
                            />
                            <AccountMenu
                                open={open}
                                anchorEl={ref?.current}
                                handleClose={handleClose}
                                items={children}
                                active={active}
                                roles={roles}
                                onOpen={onOpen}
                                isCollapse={false}
                            />
                        </>
                    ) : (
                        <Box sx={{ width: 24 }} />
                    )}
                </Stack>
            ) : (
                <>
                    {icon ? icon : null}
                    <ListItemTextStyle
                        disableTypography
                        primary={title}
                        secondary={
                            <Tooltip title={caption || ''} arrow>
                                <Typography
                                    noWrap
                                    variant='caption'
                                    component='div'
                                    sx={{ textTransform: 'initial', color: 'text.secondary' }}
                                >
                                    {caption}
                                </Typography>
                            </Tooltip>
                        }
                        isCollapse={isCollapse}
                    />
                    {info && info}
                    {hasChildren && <ArrowIcon open={open} />}
                </>
            )}
        </>
    )

    if (hasChildren) {
        return (
            <ListItem onClick={onOpen} activeRoot={active} disabled={disabled} roles={roles} sx={{ justifyContent: 'center' }}>
                {renderContent}
            </ListItem>
        )
    }

    return isExternalLink(path) ? (
        <ListItem
            component={Link}
            href={path}
            target='_blank'
            rel='noopener'
            disabled={disabled}
            roles={roles}
            sx={{ justifyContent: 'center' }}
        >
            {renderContent}
        </ListItem>
    ) : (
        <ListItem component={RouterLink} to={path} activeRoot={active} disabled={disabled} roles={roles} sx={{ justifyContent: 'center' }}>
            {renderContent}
        </ListItem>
    )
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
    active: PropTypes.bool,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    item: PropTypes.shape({
        children: PropTypes.array,
        info: PropTypes.any,
        path: PropTypes.string,
        title: PropTypes.string,
        disabled: PropTypes.bool,
        caption: PropTypes.bool,
        roles: PropTypes.arrayOf(PropTypes.string),
        icon: PropTypes.any
    })
}

export function NavItemSub({ item, open = false, active = false, onOpen }) {
    const { title, path, info, children, disabled, caption, roles, icon } = item

    const renderContent = (
        <>
            {icon ? icon : null}
            <ListItemText
                disableTypography
                primary={title}
                secondary={
                    <Tooltip title={caption || ''} arrow>
                        <Typography noWrap variant='caption' component='div' sx={{ textTransform: 'initial', color: 'text.secondary' }}>
                            {caption}
                        </Typography>
                    </Tooltip>
                }
            />
            {info && info}
            {children && <ArrowIcon open={open} />}
        </>
    )

    if (children) {
        return (
            <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
                {renderContent}
            </ListItem>
        )
    }

    return isExternalLink(path) ? (
        <ListItem component={Link} href={path} target='_blank' rel='noopener' subItem disabled={disabled} roles={roles}>
            {renderContent}
        </ListItem>
    ) : (
        <ListItem component={RouterLink} to={path} activeSub={active} subItem disabled={disabled} roles={roles}>
            {renderContent}
        </ListItem>
    )
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
    active: PropTypes.bool
}

export function DotIcon({ active }) {
    return (
        <ListItemIconStyle>
            <Box
                component='span'
                sx={{
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    bgcolor: 'text.disabled',
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shorter
                        }),
                    ...(active && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main'
                    })
                }}
            />
        </ListItemIconStyle>
    )
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
    open: PropTypes.bool
}

export function ArrowIcon({ open }) {
    return <Iconify icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ width: 16, height: 16, ml: 1 }} />
}
