// @mui
/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import { alpha, styled } from '@mui/material/styles'
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material'
// config
import { ICON, NAVBAR } from '../../../config'

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem'
})(({ activeRoot, activeSub, subItem, theme, special }) => ({
    ...theme.typography.body2,
    position: 'relative',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    borderRadius: 0,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#53378B',
    marginLeft: '20px',
    marginRight: '20px',
    ...(activeRoot && {
        ...theme.typography.subtitle2,
        color: '#53378B',
        fontWeight: 600,
        backgroundImage: 'linear-gradient(to right,#EEEBFF, white)', 
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    }),
    // activeSub
    ...(activeSub && {
        ...theme.typography.subtitle2,
        color: '#53378B',
        fontWeight: 600,
    }),
    // subItem
    ...(subItem && {
        height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT
    }),
    ...(special && {
        backgroundColor: '#EEEBFF' ,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    }
    )

}))

export const ListItemTextStyle = styled(ListItemText, {
    shouldForwardProp: (prop) => prop !== 'isCollapse'
})(({ isCollapse, theme }) => ({
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter
    }),
    ...(isCollapse && {
        width: 0,
        opacity: 0
    })
}))

export const ListItemIconStyle = styled(ListItemIcon)({
    width: ICON.NAVBAR_ITEM,
    height: ICON.NAVBAR_ITEM,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': { width: '100%', height: '100%' }
})
