import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import Lojiffybots from 'ui-component/extended/Logo'
// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
    smallIcon: PropTypes.bool,
    isPublic: PropTypes.bool
}

export default function Logo({ disabledLink = false, sx, smallIcon = false, isPublic = false }) {
    if (disabledLink) {
        return (
            <>
                <Lojiffybots smallIcon={smallIcon} />
            </>
        )
    }
    const to = '/dashboard'
    if (isPublic) {
        return (
            <a href='https://meerkats.ai/'>
                <Lojiffybots smallIcon={smallIcon} />
            </a>
        )
    }
    return (
        <RouterLink to={to}>
            <Lojiffybots smallIcon={smallIcon} />
        </RouterLink>
    )
}
