/* eslint-disable react/prop-types */
import Button from '@mui/material/Button'
import { dispatch } from 'store'
import useNotifier from 'utils/useNotifier'
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from 'store/actions'
import { IconX } from '@tabler/icons'
import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { LoadingButton } from '@mui/lab'
import { Avatar } from '@mui/material'
export default function SocialAuth({ afterLogin }) {
    const urlSubdomain = window.location.hostname.split('.')[0]
    const [loading, setLoading] = useState(false)
    const [reqSent, setReqSent] = useState(false)
    const [bcMsg, setBcMsg] = useState(null)
    const { login } = useAuth()
    const navigate = useNavigate()
    useNotifier()
    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args))
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args))
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET
    const redirectUrl =
        urlSubdomain === 'builder'
            ? process.env.REACT_APP_GOOGLE_REDIRECT_URL.replace('apps', 'builder')
            : process.env.REACT_APP_GOOGLE_REDIRECT_URL
    const scope = ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile']
    const authUrl = 'https://accounts.google.com/o/oauth2/auth'
    const bc = new BroadcastChannel('jippybot1')
    bc.onmessage = (event) => {
        if (event.data.type === 'oauth') {
            bc.close()
            setBcMsg(event.data.data)
        }
    }
    useEffect(() => {
        if (bcMsg) {
            save(bcMsg)
        }
    }, [bcMsg])
    const toast = (message, variant) => {
        enqueueSnackbar({
            message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant,
                persist: false,
                action: (key) => (
                    <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
                        <IconX />
                    </Button>
                )
            }
        })
    }
    const handleGLogin = () => {
        setLoading(true)
        let url = `${authUrl}/oauthchooseaccount?response_type=code&client_id=`
        url += `${clientId}&redirect_uri=${redirectUrl}&access_type=offline&prompt=consent&scope=${scope.join(' ')}`
        const windowFeatures = 'width=500,height=700'

        const newWindow = window.open(url, 'auth', windowFeatures)

        // Optionally, you can focus the new window
        if (newWindow) {
            newWindow.focus()
        }
    }

    const save = async (data) => {
        if (reqSent) {
            return
        }
        setReqSent(true)
        const payload = {
            client_id: clientId,
            client_secret: clientSecret,
            code: data.code,
            redirect_url: redirectUrl,
            scope: scope.join(' ')
        }
        try {
            const err = await login(payload, afterLogin)
            if (err && err.error) {
                toast(err.error, 'error')
            } else if (err && err.status === false) {
                toast(err.msg, 'error')
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            const errorData = error?.response?.data?.message
            toast(errorData ?? `Failed to login`, 'error')
            setLoading(false)
        }
    }

    return (
        <>
            <LoadingButton
                loading={loading}
                onClick={handleGLogin}
                variant='outlined'
                size='large'
                sx={{
                    width: '100%',
                    textTransform: 'capitalize',
                    mt: 3
                    // backgroundImage: 'linear-gradient(to right, #260666, #4C0CCC)',
                    // color: 'white'
                }}
                startIcon={<Avatar src='/images/google.jpg' sx={{ width: 24, height: 24 }} />}
            >
                Sign in with Google
            </LoadingButton>
        </>
    )
}
