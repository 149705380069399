// theme
import palette from '../theme/palette'

// ----------------------------------------------------------------------

export const colorPresets = [
    // DEFAULT
    {
        name: 'default',
        ...palette.light.primary
    },
    // PURPLE
    {
        name: 'purple',
        lighter: '#EBD6FD',
        light: '#B985F4',
        // main: '#7635dc',
        // main: '#260666',
        main: '#53378B',
        dark: '#431A9E',
        darker: '#200A69',
        contrastText: '#fff',
        button: '#53378B'
    },
    // CYAN
    {
        name: 'cyan',
        lighter: '#D1FFFC',
        light: '#76F2FF',
        main: '#1CCAFF',
        dark: '#0E77B7',
        darker: '#053D7A',
        contrastText: palette.light.grey[800]
    },
    // BLUE
    {
        name: 'blue',
        lighter: '#D1E9FC',
        light: '#76B0F1',
        main: '#2065D1',
        dark: '#103996',
        darker: '#061B64',
        contrastText: '#fff'
    },
    // ORANGE
    {
        name: 'orange',
        lighter: '#FEF4D4',
        light: '#FED680',
        main: '#fda92d',
        dark: '#B66816',
        darker: '#793908',
        contrastText: palette.light.grey[800]
    },
    // RED
    {
        name: 'red',
        lighter: '#FFE3D5',
        light: '#FFC1AC',
        main: '#FF3030',
        dark: '#B71833',
        darker: '#7A0930',
        contrastText: '#fff'
    },
    // JB
    {
        name: 'jb',
        ...palette.light.primary,
        'color-primary-100': '#D6E4FF',
        'color-primary-200': '#ADC8FF',
        'color-primary-300': '#84A9FF',
        'color-primary-400': '#6690FF',
        'color-primary-500': '#3366FF',
        'color-primary-600': '#254EDB',
        'color-primary-700': '#1939B7',
        'color-primary-800': '#102693',
        'color-primary-900': '#091A7A',
        'color-success-100': '#F2FCCE',
        'color-success-200': '#E2FA9E',
        'color-success-300': '#C9F06C',
        'color-success-400': '#AEE147',
        'color-success-500': '#89CE12',
        'color-success-600': '#6EB10D',
        'color-success-700': '#569409',
        'color-success-800': '#407705',
        'color-success-900': '#316203',
        'color-info-100': '#CCFFFD',
        'color-info-200': '#99FBFF',
        'color-info-300': '#66EEFF',
        'color-info-400': '#3FDCFF',
        'color-info-500': '#00BFFF',
        'color-info-600': '#0094DB',
        'color-info-700': '#006FB7',
        'color-info-800': '#004F93',
        'color-info-900': '#00397A',
        'color-warning-100': '#FFF9CC',
        'color-warning-200': '#FFF099',
        'color-warning-300': '#FFE666',
        'color-warning-400': '#FFDC3F',
        'color-warning-500': '#FFCC00',
        'color-warning-600': '#DBAA00',
        'color-warning-700': '#B78B00',
        'color-warning-800': '#936D00',
        'color-warning-900': '#7A5700',
        'color-danger-100': '#FFE7D6',
        'color-danger-200': '#FFC8AE',
        'color-danger-300': '#FFA385',
        'color-danger-400': '#FF8067',
        'color-danger-500': '#FF4635',
        'color-danger-600': '#DB2726',
        'color-danger-700': '#B71A27',
        'color-danger-800': '#931026',
        'color-danger-900': '#7A0A25'
    }
]

export const defaultPreset = colorPresets[0]
export const purplePreset = colorPresets[1]
export const cyanPreset = colorPresets[2]
export const bluePreset = colorPresets[3]
export const orangePreset = colorPresets[4]
export const redPreset = colorPresets[5]
export const jbPreset = colorPresets[6]

export default function getColorPresets(presetsKey) {
    return {
        purple: purplePreset,
        cyan: cyanPreset,
        blue: bluePreset,
        orange: orangePreset,
        red: redPreset,
        default: defaultPreset,
        jb: jbPreset
    }[presetsKey]
}
