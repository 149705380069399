import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react'
// hooks
import useResponsive from '../hooks/useResponsive'

// ----------------------------------------------------------------------

const initialState = {
    collapseClick: true,
    collapseHover: false,
    onToggleCollapse: () => {},
    onHoverEnter: () => {},
    onHoverLeave: () => {}
}

const CollapseDrawerContext = createContext(initialState)

// ----------------------------------------------------------------------

CollapseDrawerProvider.propTypes = {
    children: PropTypes.node
}

function CollapseDrawerProvider({ children }) {
    const isDesktop = useResponsive('up', 'lg')

    const [collapse, setCollapse] = useState({
        click: true,
        hover: false
    })

    useEffect(() => {
        if (!isDesktop) {
            setCollapse({
                click: true,
                hover: false
            })
        }
    }, [isDesktop])

    const handleToggleCollapse = () => {
        setCollapse({ ...collapse, click: !collapse.click })
    }

    const handleHoverEnter = () => {
        setCollapse({ ...collapse, hover: true, click: false })
    }

    const handleHoverLeave = () => {
        setCollapse({ ...collapse, hover: false, click: true })
    }

    return (
        <CollapseDrawerContext.Provider
            value={{
                isCollapse: collapse.click && !collapse.hover,
                collapseClick: collapse.click,
                collapseHover: collapse.hover,
                onToggleCollapse: handleToggleCollapse,
                onHoverEnter: handleHoverEnter,
                onHoverLeave: handleHoverLeave
            }}
        >
            {children}
        </CollapseDrawerContext.Provider>
    )
}

export { CollapseDrawerProvider, CollapseDrawerContext }
