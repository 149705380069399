import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
    isPublic: PropTypes.bool
}

export default function Logo({ disabledLink = false, sx, isPublic = false }) {
    const logo = <img width='100' src='/logo/logo.svg' alt='Meerkats.AI' />

    if (disabledLink) {
        return <>{logo}</>
    }
    if (isPublic) {
        return <a href='https://meerkats.ai/'>{logo}</a>
    }

    return <RouterLink to='/'>{logo}</RouterLink>
}
