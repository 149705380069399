import { capitalCase } from 'change-case'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Card, Stack, Link, Tooltip, Container, Typography } from '@mui/material'
// routes
// hooks
import useAuth from '../../hooks/useAuth'
import useResponsive from '../../hooks/useResponsive'
// components
import Page from '../../ui-component/Page'
import Logo from '../../ui-component/Logo'
import Image from '../../ui-component/Image'
// sections
import { LoginForm } from '../../ui-component/auth/login'
import SocialAuth from './SocialAuth'
import { useNavigate } from 'react-router-dom'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}))

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7)
    }
}))

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}))

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Login() {
    const { method } = useAuth()
    const navigate = useNavigate()
    // searchParam
    const search = window.location.search
    const params = new URLSearchParams(search)
    const redirectAfterLogin = params.get('redirectAfterLogin')

    const smUp = useResponsive('up', 'sm')

    const mdUp = useResponsive('up', 'md')

    const afterLogin = (isAdmin) => {
        if (redirectAfterLogin) {
            window.location.href = redirectAfterLogin
        } else {
            navigate('/dashboard')
        }
    }

    return (
        <Page title='Login'>
            <RootStyle>
                <HeaderStyle>
                    <Logo isPublic />
                </HeaderStyle>

                {/* {mdUp && (
                    <SectionStyle>
                        <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <Image visibleByDefault disabledEffect src='/assets/illustrations/illustration_login.png' alt='login' />
                    </SectionStyle>
                )} */}

                <Container maxWidth='sm'>
                    <ContentStyle>
                        <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant='h4' gutterBottom>
                                    Sign in to Meerkats AI
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                            </Box>

                            <Tooltip title={capitalCase(method)} placement='right'>
                                <>
                                    <Image disabledEffect src={`/logo/logo.svg`} sx={{ width: 132 }} />
                                </>
                            </Tooltip>
                        </Stack>

                        <LoginForm afterLogin={afterLogin} />
                        <SocialAuth afterLogin={afterLogin} />
                        <Typography variant='body2' align='center' sx={{ mt: 3 }}>
                            Don’t have an account?{' '}
                            <Link variant='subtitle2' component={RouterLink} to='/auth/register'>
                                Get started
                            </Link>
                        </Typography>
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    )
}
