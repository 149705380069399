export const BOT_INIT_BOT = 'BOT_INIT_BOT'
export const ADD_NEW_KB_DIALOG_OPEN = 'ADD_NEW_KB_DIALOG_OPEN'
export const ALL_KB_DIALOG_OPEN = 'ALL_KB_DIALOG_OPEN'
export const SET_BOTS = 'SET_BOTS'
export const SET_BOT = 'SET_BOT'
export const ADD_CONTENT_KB_DIALOG_OPEN = 'ADD_CONTENT_KB_DIALOG_OPEN'
export const SHARE_BOT = 'SHARE_BOT'
export const CUSTOMIZE_BOT = 'CUSTOMIZE_BOT'
export const BB_INIT_BOT = 'BB_INIT_BOT'
export const EDIT_CONTENT_KB_DIALOG_OPEN = 'EDIT_CONTENT_KB_DIALOG_OPEN'
export const OPEN_NODE_SIDEBAR = 'OPEN_NODE_SIDEBAR'
export const ADD_NEW_FLOW_OPEN = 'ADD_NEW_FLOW_OPEN'
export const IS_CHATTING = 'IS_CHATTING'
export const SET_BOT_SIDEBAR_TYPE = 'SET_BOT_SIDEBAR_TYPE'
export const SET_EDGE_ID = 'SET_EDGE_ID'
export const SET_PARENT = 'SET_PARENT'
export const UPDATE_BOT = 'UPDATE_BOT'
export const SET_KBS = 'SET_KBS'
export const SET_KB = 'SET_KB'
export const SET_ACTIVE_INTENT = 'SET_ACTIVE_INTENT'
export const SET_BOT_VARIABLES = 'SET_BOT_VARIABLES'
export const SET_EXPANDED = 'SET_EXPANDED'
export const UPDATE_BOT_LOADING = 'UPDATE_BOT_LOADING'
export const SET_ACTIVE_CONDITION = 'SET_ACTIVE_CONDITION'
export const SET_DELETING = 'SET_DELETING'
export const SET_UPDTATING = 'SET_UPDTATING'
export const ANY_UNSAVED_CHANGES = 'ANY_UNSAVED_CHANGES'
export const SET_ACTIVE_FLOW_ID = 'SET_ACTIVE_FLOW_ID'
export const setBot = (bot) => ({
    type: SET_BOT,
    payload: bot
})
export const setDeleting = (payload) => ({
    type: SET_DELETING,
    payload
})
export const setUpdating = (payload) => ({
    type: SET_UPDTATING,
    payload
})
export const setBots = (bots) => ({
    type: SET_BOTS,
    payload: bots
})
export const openAddNewKbDialog = (payload) => ({
    type: ADD_NEW_KB_DIALOG_OPEN,
    payload
})
export const openAllKbDialog = (payload) => ({
    type: ALL_KB_DIALOG_OPEN,
    payload
})
